import {connect} from 'react-redux';
import { Button, ButtonGroup, Grid, Stack } from "@mui/material";
import MapIcon from '@mui/icons-material/Map';
import Person2Icon from '@mui/icons-material/Person2';
import TableRowsIcon from '@mui/icons-material/TableRows';
import WindowIcon from '@mui/icons-material/Window';
import FlexBox from "./flexbox/FlexBox";
import FlexBetween from "./flexbox/FlexBetween";
import Heading from './Heading';
import FilterList from "../icons/FilterList";
import {setView} from '../actions'

const ViewHeader = ({view, setView}) => {
    console.log("view inside viewHeader:", view);
    return (
        <Grid item xs={12} xl={12}>
            <FlexBetween flexWrap="wrap" mb={0} ml={1} mr={1}>
              <Heading title="Bhaktas" Icon={Person2Icon} />
              <Stack alignItems="flex-end" mb={0}>
                  <FlexBox alignItems="flex-end" gap={2} >
                    {false && <Button
                      color="primary"
                      variant="contained"
                      startIcon={<FilterList />}
                      //onClick={() => console.log("Filter clicked" + this.state.currentView)}
                    >
                      Filter
                    </Button>}
                    <Stack direction="row">
                    <ButtonGroup aria-label="outlined primary button group">
                      <Button onClick={() => setView("map")} variant={(view.viewType==="map")? "contained":"outlined"}><MapIcon/></Button>
                      <Button onClick={() => setView("table")} variant={(view.viewType==="table")? "contained":"outlined"}><TableRowsIcon/></Button>
                      <Button onClick={() => setView("grid")}  variant={(view.viewType==="grid")? "contained":"outlined"}><WindowIcon/></Button>
                    </ButtonGroup>
                    </Stack>
                  </FlexBox>
                </Stack>
              </FlexBetween>
            </Grid>
    );
}

const mapStateToProps = (state) => {
    //console.log("view in ViewHeader", state.view);
    return {
      view: state.view
    };
  };

export default connect(mapStateToProps, {setView})(ViewHeader);
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Stack
} from "@mui/material";
//import Chip from '@material-ui/core/Chip';
//import Col from 'react-bootstrap/Col';
import { FaTrashAlt } from "react-icons/fa";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Links from '../components/Links';
import { PhotoCamera, KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  styled,
  Switch,
} from "@mui/material";
import AppTextField from "../components/input-fields/AppTextField";
import { H6, Small, Tiny } from "../components/Typography";
import { useFormik } from "formik";
import * as Yup from "yup"; // styled components
import { StyledFormControlLabel, StyledSelectInput } from "../components/StyledComponent";
import FlexBetween from "../components/flexbox/FlexBetween";
import Gallery from '../components/Gallery';
import HTMLEditor from "../components/input-fields/HTMLEditor";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  addPicFont:{
      fontSize: 100
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  addPicAlignment: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chipFont:{
      fontSize:15,
      fontWeight: 'bold'
  }
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: 10,
    flexDirection: "row",
    "& .carousel__dot": {
      marginTop: 0,
      marginRight: 8,
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  top: 10,
  right: 10,
  position: "absolute",
  backgroundColor: theme.palette.grey[400],
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
  },
}));

const renderTextBox = ({ input, label, initialValues, meta: { touched, error }  }) => (
  <div>
  
    <AppTextField
                    
                    fullWidth
                    name={input.name}
                    label={label}
                    value={input.value}
                    onChange={input.onChange}
                    disabled={initialValues && initialValues.name}
                    variant="outlined"
                    onBlur={input.onBlur}
                    error={touched && error}
                    helperText={touched && error}
                  />
  </div>
)

const renderDropDown = ({ input, label, id, children, meta: { touched, error }}) => (
  <FormControl fullWidth error={!!error}>
    <Select
      label={label}
      labelId={id}
      id={id}
      value={input.value}
      onChange={input.onChange}
      children={children}
      IconComponent={() => <KeyboardArrowDown />}
      input={<StyledSelectInput />}
      onBlur={input.onBlur}
      error={touched && error}
      >
    </Select>
     {touched && error &&  <FormHelperText>{error}</FormHelperText>}
    </FormControl>
)

const renderCheckbox = ({input, label, checked}) => (
  <FormGroup>
      <FormControlLabel control={<Checkbox name={input.name} checked={checked || input.value} onChange={input.onChange}/>} label={label} />
  </FormGroup>
)

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 71,
    label: '71',
  },
];

const renderSlider = ({input, label, yugaCycleUnknownChecked, initialValues}) => (
  <Slider
  aria-label="Always visible"
  defaultValue={28}
  step={1}
  marks={marks}
  max={71}
  valueLabelDisplay="on"
  disabled={yugaCycleUnknownChecked || (initialValues && initialValues.yugaCycleUnknownChecked)}
  name={input.name}
  value={input.value || 28}
  onChange={input.onChange}
/>
);

const renderAutoComplete = ({input, label, cities, meta: { touched, error }}) => (
  <FormControl fullWidth error={error}>
    <Autocomplete
      freeSolo
      disablePortal
      id="combo-box-demo"
      options={cities.map(city => city.name + "," + city.state + ", " + city.country)}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option}
          </li>
        );
      }}
      renderInput={(params) => <AppTextField {...params} label="City" name="location"/>}
      value={input.value}
      //error={touched && error}
      //helperText={error}
      onChange={(event, value) => {
        console.log(value)
        input.onChange(value);
      }}
    />
    {error &&  <FormHelperText>{error}</FormHelperText>}
  </FormControl>
  
);

const SwitchWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: 10,
}));

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Bhakta name is required';
  }
  if (!values.location) {
    errors.location = 'Bhakta location is required';
  }
  if (!values.type) {
    errors.type = 'Type of Bhakta is required';
  }
  if (!values.god) {
    errors.god = 'God is required';
  }
  if (!values.kalpa) {
    errors.kalpa = 'Kalpa is required';
  }
  if (!values.manvantara) {
    errors.manvantara = 'Manvantara is required';
  }
  if (!values.yuga) {
    errors.yuga = 'Yuga is required';
  }
  return errors
}

function CreateUserForm({cities, change, handleFileupload, initialValues, handleSubmit, pristine, reset, submitting}) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const [gods, setGods] = React.useState([{"name":"Ganesha", "display": true, "key":"ganesha"},
  {"name":"Shiva", "display": true, "key":"shiva"},
  {"name":"Vishnu", "display": true, "key":"vishnu"},
  {"name":"Skanda", "display": false, "key":"skanda"},
  {"name":"Shakti", "display": false, "key":"parvati"}]);

  const [god, setGod] = React.useState('');

  const [eons, setEons] = React.useState([{"name":"Satya", "display": true, "key":"satya"},
  {"name":"Treta", "display": true, "key":"treta"},
  {"name":"Dwapara", "display": true, "key":"dwapara"},
  {"name":"Kali", "display": true, "key":"kali"},
  {"name":"Unknown", "display": true, "key":"unknown"}]);
  const [eon, setEon] = React.useState('');

  const [bhaktTypes, setBhaktTypes] = React.useState([{"name":"Paramatma", "display": true, "key":"satya"},
  {"name":"Bhakta", "display": true, "key":"satya"},
  {"name":"Alwar", "display": true, "key":"treta"},
  {"name":"Nayanar", "display": true, "key":"dwapara"},
  {"name":"Sati", "display": true, "key":"kali"}]);
  const [bhaktType, setBhaktType] = React.useState('');

  const [kalpas, setKalpas] = React.useState([{"name":"Bramha", "display": true, "key":"brahma"},
  {"name":"Padma", "display": true, "key":"padma"},
  {"name":"Sweta Varaha", "display": true, "key":"swetaVaraha"},
  {"name":"Unknown", "display": true, "key":"unknown"}]);

  const [manvantaras, setManvantaras] = React.useState([{"name":"Swayambhu", "display": true, "key":"swayambhu"},
  {"name":"Swarochisha", "display": true, "key":"swarochisha"},
  {"name":"Uttama", "display": true, "key":"uttama"},
  {"name":"Tapasa", "display": true, "key":"tapasa"},
  {"name":"Swarochisha", "display": true, "key":"swarochisha"},
  {"name":"Uttama", "display": true, "key":"uttama"},
  {"name":"Chakshusha", "display": true, "key":"chakshusha"},
  {"name":"Vaivasvatha", "display": true, "key":"vaivasvatha"},
  {"name":"Savarni", "display": true, "key":"savarni"},
  {"name":"Daksha Savarni", "display": true, "key":"dakshaSavarni"},
  {"name":"Brahma Savarni", "display": true, "key":"brahmaSavarni"},
  {"name":"Dharma Savarni", "display": true, "key":"dharmaSavarni"},
  {"name":"Rudra Savarni", "display": true, "key":"rudraSavarni"},
  {"name":"Raucya Savarni", "display": true, "key":"raucyaSavarni"},
  {"name":"Indra Savarni", "display": true, "key":"indraSavarni"},
  {"name":"Unknown", "display": true, "key":"unknown"}]);

  const [centuries, setCenturies] = React.useState(["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th",
  "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21st"]);
  const [century, setCentury] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [yugaCycleUnknownChecked, setYugaCycleUnknownChecked] = React.useState(false);
  const linkTableColumns = [{
    Header: 'Name',
    accessor: 'name', // accessor is the "key" in the data
  },
  {
    Header: 'URL',
    accessor: 'url',
  }];

  const handleYugaUnknownChange = (event) => {
    console.log("unknown cycle checked:" + event.target.checked);
    setYugaCycleUnknownChecked(event.target.checked);
  };

  const initialFormikValues = {
    bhaktaName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    address: "",
    zip: "",
    about: "",
  };
  const validationSchema = Yup.object().shape({
    bhaktaName: Yup.string().required("Name is Required!"),
    email: Yup.string().email().required("Email is Required!"),
    phone: Yup.number().min(8).required("Phone is Required!"),
    country: Yup.string().required("Country is Required!"),
    state: Yup.string().required("State is Required!"),
    city: Yup.string().required("City is Required!"),
    address: Yup.string().required("Address is Required!"),
    zip: Yup.string().required("Zip is Required!"),
    about: Yup.string().required("About is Required!"),
  });
  const { values, errors, handleChange, handleFormikSubmit, touched } = useFormik({
    initialValues: initialFormikValues,
    validationSchema,
    onSubmit: () => {},
  });

  const [language, setLanguage] = React.useState('english');
  const handleLanguageChange = (event) => {
    console.log("Language changed:", event);
    setLanguage(event.target.value);
  }

  const languages = [
    {name:"English", value:"english"}, {name:"हिंदी", value:"devanagari"}, {name:"தமிழ்", value:"tamil"}, {name:"తెలుగు", value:"telugu"}, {name:"ಕನ್ನಡ", value:"kannada"}
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Box pt={2} pb={4}>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Card
            sx={{
              padding: 3,
              minHeight: 400,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <Gallery editMode={true} photos={(initialValues && initialValues.photos)?initialValues.photos: null}/>
          </Card>
          <Links links={(initialValues && initialValues.links)? initialValues.links: null} editMode={true}/>
        </Grid>
        <Grid item md={8} xs={12}>
          <Card
            sx={{
              padding: 3,
            }}
          >
          
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                    <Field name="name" component={renderTextBox} label="Name" initialValues={initialValues}/>
                </FormControl>
                  
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                      <Field name="location" component={renderAutoComplete} label="City" initialValues={initialValues} cities={cities}/>
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <H6 mb={1}>Type</H6>
                  <FormControl className={classes.formControl} fullWidth>
                      <Field name="type" component={renderDropDown} label="Type" id="type">
                        {bhaktTypes.map(type => {
                                return <MenuItem value={type.name} sx={{fontSize: 12, fontWeight: 500}}>{type.name}</MenuItem>
                            })}
                      </Field>
                </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <H6 mb={1}>God</H6>
                  <FormControl className={classes.formControl} fullWidth>
                      <Field name="god" component={renderDropDown} label="God" id="god">
                        {gods.map(god => {
                                return <MenuItem value={god.name} sx={{fontSize: 12, fontWeight: 500}}>{god.name}</MenuItem>
                            })}
                      </Field>
                </FormControl>
                </Grid>
                  
                <Grid item sm={6} xs={12}>
                  <H6 mb={1}>Kalpa</H6>
                  <FormControl className={classes.formControl} fullWidth>
                      <Field name="kalpa" component={renderDropDown} label="Kalpa" id="type">
                        {kalpas.map(kalpa => {
                                return <MenuItem value={kalpa.name} sx={{fontSize: 12, fontWeight: 500}}>{kalpa.name}</MenuItem>
                            })}
                      </Field>
                </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <H6 mb={1}>Manvantara</H6>
                  <FormControl className={classes.formControl} fullWidth>
                      <Field name="manvantara" component={renderDropDown} label="Manvantara" id="manvantara">
                        {manvantaras.map(manvantara => {
                                return <MenuItem value={manvantara.name} sx={{fontSize: 12, fontWeight: 500}}>{manvantara.name}</MenuItem>
                            })}
                      </Field>
                </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  
                  <FlexBetween
                    marginTop={0}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    >
                      <H6 mb={1}>Chatur Yuga Cycle</H6>
                      <FormControl className={classes.formControl}>
                        <Field name="yugaCycleUnknown" component={renderCheckbox} label="Unknown" onChange={handleYugaUnknownChange} checked={yugaCycleUnknownChecked} id="yugaCycleUnknown"></Field>
                      </FormControl>
                  </FlexBetween>
                    <FormControl className={classes.formControl} fullWidth>
                        <Field name="yugaCycle" component={renderSlider} label="Chatur Yuga Cycle" yugaCycleUnknownChecked={yugaCycleUnknownChecked} id="yugaCycle" initialValues={initialValues}>
                        </Field>
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                <H6 mb={1}>Yuga</H6>
                  <FormControl className={classes.formControl} fullWidth>
                      <Field name="yuga" component={renderDropDown} label="Yuga" id="yuga">
                        {eons.map(eon => {
                                return <MenuItem value={eon.name} sx={{fontSize: 12, fontWeight: 500}}>{eon.name}</MenuItem>
                            })}
                      </Field>
                </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                <H6 mb={1}>Century</H6>
                  <FormControl className={classes.formControl} fullWidth>
                      <Field name="century" component={renderDropDown} label="Century" id="yuga">
                        {centuries.map(century => {
                                return <MenuItem value={century} sx={{fontSize: 12, fontWeight: 500}}>{century}</MenuItem>
                            })}
                      </Field>
                </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} sx={{paddingTop:"20px"}}>
                
                  <FormControl className={classes.formControl} fullWidth>
                    <Field name="year" component={renderTextBox} label="Year" initialValues={initialValues}/>
                </FormControl>
                </Grid>
                
                <Grid item xs={6}>
                  <Select
                    value={language}
                    //onChange={(e) => setLanguage(e.target.value)}
                    onChange={handleLanguageChange}
                    IconComponent={() => <KeyboardArrowDown />}
                    input={<StyledSelectInput />}
                  >
                  {languages.map(lang => (
                    <MenuItem
                    value={lang.value}
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {lang.name}
                  </MenuItem>
                  )
                  )}
                    
                  </Select>
                </Grid>
                <Grid item xs={12}>
                   <HTMLEditor /*about={(initialValues && initialValues.about)? initialValues.about: null}*/ language={language}/>
                </Grid>
                
              </Grid>
            
          </Card>
        </Grid>
      </Grid>
    </Box>
      
    </form>
  );
}

export default reduxForm({
  form: 'createUserForm',  // a unique identifier for this form     
  //touchOnChange: true,
  validate          
})(CreateUserForm)

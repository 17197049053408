import React from 'react';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    fontWeight: 'bold'
  },
  divider: {
    borderBottom: '2px solid',
    borderWidth: 'thick'
  }
});

export default function SelectionHeading({headingText}) {
  const classes = useStyles();

  return (
    <div>
      <ListItem button>
          <ListItemText primary={headingText} 
          classes={{
            root: classes.root, // class name, e.g. `classes-nesting-root-x`
          }}
          disableTypography={true}
          />
      </ListItem>
      <Divider classes={{
            root: classes.divider
          }}/>
    </div>
  );
}


import { H6, Small, Tiny } from "../../components/Typography";

const EventsColumnShape = [
  {
    Header: "Location",
    accessor: "location",
    minWidth: 200,
  },
  {
    Header: "April",
    accessor: "april",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.april.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "May",
    accessor: "may",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.may.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "June",
    accessor: "june",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.june.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "July",
    accessor: "july",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.july.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "August",
    accessor: "august",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.august.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "September",
    accessor: "september",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.september.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "October",
    accessor: "october",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.october.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "November",
    accessor: "november",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.november.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "December",
    accessor: "december",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.december.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "January",
    accessor: "january",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.january.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "February",
    accessor: "february",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.february.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  },
  {
    Header: "March",
    accessor: "march",
    minWidth: 150,
    Cell: ({ row }) => {
      console.log("Events from EventsColumnShape:", row);
      return row.values.march.map( event => {
        return (
          <div
          sx={{
            borderRadius: 10,
            padding: ".2rem 1rem",
            textAlign: "center",
          }}
        >
          {event.dates}:{event.name}
        </div>
        );
      });
    }
  }
];
export default EventsColumnShape;

import React, { Component } from 'react';
import { Field, reduxForm, FormSection, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
//import Column from 'react-bootstrap/Column';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Typeahead } from 'react-bootstrap-typeahead';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SelectionHeading from '../components/wrappers/SelectionHeading'


class SelectionForm extends Component {

  constructor(props) {
    super(props);

    this.state = { gods: [{"name":"Ganesha", "display": true, "key":"ganesha"},
    {"name":"Shiva", "display": true, "key":"shiva"},
    {"name":"Vishnu", "display": true, "key":"vishnu"},
    {"name":"Brahma", "display": false, "key":"brahma"},
    {"name":"Shakti", "display": false, "key":"parvati"},
    {"name":"Lakshmi", "display": false, "key":"lakshmi"},
    {"name":"Saraswati", "display": false, "key":"saraswati"},],
    eons: [{"name":"Satya", "display": true, "key":"satya"},
    {"name":"Treta", "display": true, "key":"treta"},
    {"name":"Dwapara", "display": true, "key":"dwapara"},
    {"name":"Kali", "display": true, "key":"kali"}],
    countries: [{"name":"Bharat", "display": true, "key":"bharat"},
    {"name":"Nepal", "display": true, "key":"nepal"},
    {"name":"Sri Lanka", "display": true, "key":"sriLanka"},
    {"name":"Bangladesh", "display": false, "key":"bangladesh"},
    {"name":"Pakistan", "display": false, "key":"pakistan"},
    {"name":"Afghanistan", "display": false, "key":"afghanistan"}] };

    this.imageRef = React.createRef();
  }
  renderError = (meta) => {
    if (meta.touched && meta.error) {
      return (
        <div className="ui error message">
          <div className="header">{meta.error}</div>
        </div>
      );
    }
  };

  renderReactToggle = (formProps) => {
    return (
      <Checkbox toggle />
    );
  };

  renderToggle = (formProps) => {
    return (
      <div className="ui fitted toggle checkbox">
        <input type="checkbox" className="hidden" readOnly="" tabIndex="0"/>
        <label></label>
      </div>
    );
  };

  renderCheckbox = (formProps) => {

    return (
      <div>
        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>
        <label htmlFor="vehicle1"> I have a bike</label><br></br>
      </div>
    );
  }
 renderMaterialCheckbox = ({ input, label }) => (
    <div>
      <Checkbox label={label}
      checked={input.value ? true : false}
      onChange={input.onChange}/>
      <label htmlFor="vehicle1"> {label}</label><br></br>
    </div>
    
  )

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  validateCheckBoxes = (formValues) => {
    this.props.validate(formValues);
  };

  showMoreGods = (event) => {
    console.log("Showing more gods...");
    event.preventDefault();
    let index = this.state.gods.findIndex(god => !god.display);
    let gods = this.state.gods;
    for(let i=0; i<3; i++){
      if(gods[index + i]){
        gods[index + i].display = true;
      }
    }
    this.setState({"gods": gods});
  }

  showLessGodsSSS = (event) => {
    event.preventDefault();
    let reversedGods = this.state.gods.slice().reverse();
    let index = reversedGods.findIndex(god => god.display);
    for(let i=0; i<3; i++){
      if(reversedGods[index + i] && index + i < reversedGods.length -2){
        reversedGods[index + i].display = false;
      }
    }
    this.setState({"gods": reversedGods.reverse()});
  }

  showLessGods = (event) => {
    event.preventDefault();
    let gods = this.state.gods.map((god, i) => {
      return (i < 3)?god: {...god, display:false}
    });
    this.setState({"gods": gods});
  }

 formUpdated = () => {
   let formUpdated = false;
    if(this.props.gods){
      return Object.keys(this.props.gods).length > 0;
    }
    /*return (this.props.gods && this.props.gods.keys.length > 0) || 
    (this.props.yugas.keys && this.props.yugas.keys.length > 0) || 
    (this.props.countries.keys || this.props.countries.keys.length > 0);*/
    console.log("this.props:", this.props);
  }

  showMoreCountries = (event) => {
    event.preventDefault();
    let index = this.state.countries.findIndex(country => !country.display);
    let countries = this.state.countries;
    for(let i=0; i<3; i++){
      if(countries[index + i]){
        countries[index + i].display = true;
      }
    }
    this.setState({"countries": countries});
  }

  showLessCountries = (event) => {
    event.preventDefault();
    let countries = this.state.countries.map((country, i) => {
      return (i < 3)?country: {...country, display:false}
    });
    this.setState({"countries": countries});
  }

  preventDefault = (event) => event.preventDefault();

  render() {
    const useStyles = makeStyles({
      root: {
        fontWeight: 'bold'
      }
    });
    //const classes = useStyles();
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error leftMenu"
      >
        <SelectionHeading headingText="God"></SelectionHeading>
        <FormSection name="gods">
        {this.state.gods.filter(god => god.display).map(god => {
          return (
              <ListItem key={god.key} role={undefined} dense button>
                <ListItemIcon>
                  <Field name={god.key} component={this.renderMaterialCheckbox} label={god.name}/>
                </ListItemIcon>
              </ListItem>
          )
        })}
        </FormSection>
      {this.state.gods.findIndex(god => !god.display) != -1 && 
        <Link href="#" onClick={this.showMoreGods}>
        Show more gods 
        <FaChevronDown></FaChevronDown>
      </Link>
      }
      {this.state.gods.findIndex(god => !god.display) === -1 && 
        <Link href="#" onClick={this.showLessGods}>
        Show less gods 
        <FaChevronUp></FaChevronUp>
      </Link>
      }
      <Divider light={false}/>

      <SelectionHeading headingText="Yuga"></SelectionHeading>
      <FormSection name="yugas">
      {this.state.eons.map(eon => {
          return (
              <ListItem key={eon.key} role={undefined} dense button>
                <ListItemIcon>
                  <Field name={eon.key} component={this.renderMaterialCheckbox} label={eon.name}/>
                </ListItemIcon>
              </ListItem>
          )
        })}
      </FormSection>
      <Divider />

      <SelectionHeading headingText="Country"></SelectionHeading>
      <FormSection name="countries">
        {this.state.countries.filter(country => country.display).map(country => {
            return (
                <ListItem key={country.key} role={undefined} dense button>
                  <ListItemIcon>
                    <Field name={country.key} component={this.renderMaterialCheckbox} label={country.name}/>
                  </ListItemIcon>
                </ListItem>
            )
          })}
      </FormSection>
      {this.state.countries.findIndex(country => !country.display) != -1 && 
        <Link href="#" onClick={this.showMoreCountries}>
        Show more countries 
        <FaChevronDown></FaChevronDown>
      </Link>
      }
      {this.state.countries.findIndex(country => !country.display) === -1 && 
        <Link href="#" onClick={this.showLessCountries}>
        Show less countries 
        <FaChevronUp></FaChevronUp>
      </Link>
      }

      {this.formUpdated() && console.log("form uopdated!!!")}
        
      </form>
    );
  }
}



const validate = (formValues) => {
  console.log("formValues in validate", formValues);
  //this.validateCheckBoxes(formValues);
  const errors = {};
  if (!formValues.title) {
    errors.title = 'You must enter a title';
  }

  if (!formValues.description) {
    errors.description = 'You must enter a description';
  }

  return errors;
};

const selector = formValueSelector('SelectionForm'); 

const mapStateToProps = (state) => {
  const {gods, yugas, countries} = selector(state, 'gods', 'yugas', 'countries');
  console.log("form in selection:", state)
  console.log("gods in selection:", gods)
  return {
    gods,
    yugas,
    countries
  };
}

SelectionForm = reduxForm({
  form: 'SelectionForm',
  validate: validate,
})(SelectionForm);

SelectionForm = connect(mapStateToProps, {})(SelectionForm);

export default SelectionForm;

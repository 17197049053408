import React from 'react';
import { NewReleases } from "@mui/icons-material";
import { Avatar, Box, Grid, ListItem, ListItemIcon, ListItemText, ListItemAvatar } from "@mui/material";
import { H1, H6, Small, Span } from "./Typography";

const About = () => {
  return (
    <React.Fragment>
      <Box py={1} sx={{backgroundImage: `url("maha-periyava.png")`,backgroundSize:'cover', width:'100%', height:'400px', backgroundPosition:'center', backgroundRepeat:'no-repeat'}}>
      <Box textAlign="center" maxWidth={700} margin="auto">
        <Small letterSpacing={2} fontWeight={800} color="common.white">
        GURU SAMARPANAM
        </Small>

        <H1 fontSize={22} fontWeight={800} lineHeight={1.3} color="common.white">
        Shri Gurubhyonamaha Guru Dhyanam
        </H1>
      </Box>
    </Box>
    <Box>
      <Box pt={6}>
        <Grid container spacing={3}>
          {data.map((item, index) => (
            <Grid item md={4} key={index}>
              <ListItem
                sx={{
                  alignItems: "flex-start",
                }}
              >
                <ListItemAvatar
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: "primary.main",
                    marginRight:1
                  }}
                >
                  {false && <NewReleases
                    sx={{
                      color: "common.white",
                    }}
                  />}
                  <Avatar alt="Maha Periyava" src={item.image} />
                </ListItemAvatar>
                <ListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                  sx={{
                    marginY: 0,
                    "& .MuiListItemText-primary": {
                      fontSize: 16,
                      color: "text.primary",
                      fontWeight: 600,
                      lineHeight: 1,
                    },
                    "& .MuiListItemText-secondary": {
                      color: "text.primary",
                      fontSize: 16,
                      fontWeight: 500,
                      paddingTop: 1,
                      fontFamily: 'Noto Sans Devanagari',
                    },
                  }}
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box textAlign="center" maxWidth="90%" margin="auto">
        <H6 color="text.secondary" fontSize={18} py={4} fontWeight={400}>
        Guru provides direction and Marga Dasrshakam. They live for others and teach us to live for others (
          <Span fontWeight={900} color="primary.main">Jana Janma Uddara Kakshintacha</Span>). They Always provide Prakasha(radience) all around to do good. They are strong followers of Dharma, show how to perform Dharma, live life in Dharmic Style. <Span fontWeight={600} color="primary.main">Dharmo Rakshiti, Rakshitaha.</Span> With out Guru we cannot do any activity - <Span fontWeight={600} color="primary.main">Guru Parethe Sadana Nahin.</Span> 
          Keep Guru infront of any activity we do and take every Step to the follow Guru's teachings and Complete Samparpanam to Guru!! This Chaitanya Kuteera Prakasha is started by my Guruji to ensure we know about Bhakata's /Saints who were born in our Bharatha Bhumi to foster Bhakti and teach us Dharma. Our namaskaram to Guruji and get his blessing to spread this to wider people to know about Bhakta's more in detail. 
          <Span fontWeight={900} color="primary.main">Guru Charana Vindyabhya namaha.</Span>
          </H6>
      </Box>
    </Box>
    </React.Fragment>
    
  );
};

const data = [
  {
    primary: "श्री चन्द्रशेखर सरस्वती",
    secondary: "अपार करुणा सिन्धुम् ज्ञानतम शांत रूपिणम्, श्री चन्द्रशेखर गुरूम् प्रणमामि मुथन्वाहम्.",
    image: "maha-periyava.jpeg"
  },
  {
    primary: "श्री चन्द्रशेखर भारती",
    secondary: "सदात्मा ध्यान निर्मितम् विषयेभ्यव परान्मुखम् नौमि शास्त्रेषु निष्णातम चन्द्र शेखर भारतीम्",
    image: "chandrashekhara-bharathi.png"
  },
  {
    primary: "श्री आदि शंकराचार्य",
    secondary: "शंकरम् शंकराचार्यम् केशवम् बाधरायणम् सूत्र भाष्य कृतम् वंदे भगवन्तफ पुनफ पुन:",
    image: "adi-shankaracharya.jpeg"
  },
];
export default About;

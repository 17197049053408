import { Add, MoreHoriz } from "@mui/icons-material";
import {
  AvatarGroup,
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  LinearProgress,
  styled,
} from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AppAvatar from "./avatars/AppAvatar";
import FlexBetween from "./flexbox/FlexBetween";
import FlexBox from "./flexbox/FlexBox";
import { H5, H6, Paragraph, Small } from "./Typography";
import { useNavigate } from "react-router-dom";
const StyledAvatarGroup = styled(AvatarGroup)(() => ({
  justifyContent: "flex-end",
  "& .MuiAvatarGroup-avatar": {
    width: 25,
    height: 25,
    fontSize: 12,
  },
}));

const ProjectCard = ({event  }) => {
  const navigate = useNavigate();
  const determineColor = (location) => {
    switch (location) {
      case "Bodhendra Tapo Bhumi - Muttam":
        return "#8f0904";
      case "Bodhendra Ucchishta Bhumi":
        return "#fa5d02";
      case "Veda Patashala - Perambur":
        return "#bab104";
      case "Goshala":
        return "#92ab03";
      case "Akhilandeshwari Sametha Jambukeshwara Swamy Temple":
        return "#4eed05";
      case "Sridevi Bhudevi Sametha Varadarajaswamy Temple":
        return "#013d17";
      case "Poorna Pushkalamba Sametha Dharmashastha Temple":
        return "#0fa896";
      case "Shivagami Sametha Prathapa Simheshwara Swamy Temple":
        return "#036687";
      case "Lakshmi Narayana Swamy Temple":
        return "#0c025c";
      case "Guruji Patashala - Bengaluru":
        return "#5c0250";
      default:
        return "primary";
  }
  }
  return (
    <Card
      sx={{
        padding: "1rem 1.5rem",
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
        onClick={() => navigate("/dashboards/project-details")}
      >
        <FlexBetween>
          
          <H6 color="text.secondary">{event.tamilMasam}</H6>
          <H6 sx={{paddingX:2}}>{event.month}</H6>
          <H6 color="text.secondary">{event.teluguMasam}</H6>
        </FlexBetween>

        

        <Paragraph
          mt={1}
          mb={3}
          fontSize={12}
          fontWeight={500}
          lineHeight={1.8}
          color="text.secondary"
        >
          {event.events.map(ev => {
            return (
              <div>
                <FlexBetween pt={0.5}>
                  <span><CalendarMonthIcon/> {ev.dates}</span>
                  {false && <PlaceIcon/>}
                  {false && <Chip label={ev.name} color="primary" />}
                  <Small
                    sx={{
                      backgroundColor: determineColor(ev.location),
                      color: "background.paper",
                      borderRadius: 10,
                      padding: ".2rem 1rem",
                      textAlign: "center",
                    }}
                  >
                    {ev.name}
                  </Small>
                </FlexBetween>
              </div>
            );
          })}
        </Paragraph>
      </Box>

      {false && <FlexBetween py={1}>
        <Paragraph fontWeight={600}>Project Progress</Paragraph>
        <Paragraph fontWeight={600}>32%</Paragraph>
      </FlexBetween>}

      {false && <LinearProgress variant="determinate" value={32} />}

      {false && <FlexBetween pt={3}>
        <FlexBox alignItems="center">
          <StyledAvatarGroup>
            <AppAvatar alt="Remy Sharp" src="/static/avatar/001-man.svg" />
            <AppAvatar alt="Travis Howard" src="/static/avatar/002-girl.svg" />
            <AppAvatar alt="Cindy Baker" src="/static/avatar/003-boy.svg" />
          </StyledAvatarGroup>
          <Small ml={1}>+ 15 people</Small>
        </FlexBox>

        <Button variant="dashed">
          <Add
            fontSize="small"
            sx={{
              color: "grey.600",
            }}
          />
        </Button>
      </FlexBetween>}
    </Card>
  );
};

export default ProjectCard;

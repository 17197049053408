import React, { Component } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import {
  Box
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { fetchPlaces, filterBhaktas } from '../actions';
import {UPDATE_NAME_FILTER} from '../actions/types';
import Modal from './Modal';
import MapPositions from './MapPositions'
import BhaktaPopup from './Popups/BhaktaPopup';
import SearchArea from './input-fields/search-area';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class BharatMap extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  componentDidMount() {
    //this.props.fetchPlaces();
  }

  renderPositions = () => {
    return this.props.places.map(place => {
      console.log("place", place);
      return (
        <Link style={{ color: '#006064' }} to={ place.name}>
          <MapPositions place={place}>
          </MapPositions>
        </Link>
        
      )
    })
  }

  renderPositionsSSSS = () => {
    return this.props.places.map(place => {
      console.log("place", place);
      return (
        <div>
          <Marker position={place.coordinates}>
            <Popup>
              <Modal modalOpen={true}></Modal>
            </Popup>
          </Marker>
        </div>
      )
    })
  }

  renderPositionsSSSSSSSSSSSSSS = () => {
    return this.props.places.map(place => {
      console.log("place", place);
      return (
        <Marker position={place.coordinates}>
          <Popup>
            <Modal modalOpen={true}></Modal>
          </Popup>
        </Marker>
      )
    })
  }

  renderss = () => {
    const positions = [[25.317, 82.973], [19.997, 73.789]];
    const position2 = [25.317, 82.973];
    return (
      <MapContainer className="bharatMap" center={positions[0]} zoom={5} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={positions[0]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
        </Marker>
        <Marker position={positions[1]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
        </Marker>
      </MapContainer>
    );
  }

  render() {
    const positions = [[25.317, 82.973], [19.997, 73.789]];
    const position2 = [25.317, 82.973];
    const handleSearch = (value) => {
      //console.log("filterData:", this.props.filterData);
      this.props.filterBhaktas(UPDATE_NAME_FILTER, value);
    };
    return (
      <React.Fragment>
        <Box sx={{display: { xs: 'flex', md: 'none' }}}>
        <SearchArea
              value={this.props.filterData.bhaktaName}
              onChange={handleSearch}
              placeholder="Bhakta Name..."
          />
        </Box>
        <MapContainer className="bharatMap" center={positions[0]} zoom={4} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {this.renderPositions()}
        </MapContainer>
        <BhaktaPopup/>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.status.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  console.log("places in mapStateToProps", state);
  return {
    places: state.places.activePlaces,
    status: state.status,
    filterData:state.filter
  };
};

export default connect(mapStateToProps, { fetchPlaces, filterBhaktas })(BharatMap);


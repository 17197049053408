import { Add } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import { Button, Card, Grid, Tab, useMediaQuery } from "@mui/material";

import { Box, styled } from "@mui/system";
import FlexBox from "./flexbox/FlexBox";
import { H2 } from "./Typography";
//import ProjectCard from "./ProjectCard";
import EventsColumnShape from "../assets/table-shapes/EventsColumnShape";
import CustomTable from "../components/wrappers/CustomTable";
import { useState } from "react"; // styled component

const StyledTabList = styled(TabList)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
    "& .Mui-selected": {
      "& h2, & h5": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

const Events = () => {
  const [value, setValue] = useState("0");
  const [openModal, setOpenModal] = useState(false);
  const [projectMoreEl, setProjectMoreEl] = useState(null);
  const downSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleProjectMoreClose = () => setProjectMoreEl(null);

  const handleProjectMoreOpen = (event) => {
    setProjectMoreEl(event.currentTarget);
  };

  const projectList = [
    "in-progress",
    "upcoming",
    "blocked",
    "projects-total",
    "in-progress",
    "upcoming",
    "blocked",
    "projects-total",
    "upcoming",
  ];
  
  const eventsList = [
    {
      "location": "Bodhendra Tapo Bhumi - Muttam",
      "city": "Perambur",
      "april": [
        {
        "name": "Rama Namam. Archana",
        "dates": "15th"
        },
        {
          "name": "Rama Navaham",
          "dates": "17th to 25th"
        },
        {
          "name": "Avahanti Homam",
          "dates": "23rd"
        }
      ],
      "may": [
        {
          "name": "Vishnu Sahasra Namam/Bodhendra Ashtottaram",
          "dates": "2nd"
        },
        {
          "name": "Rama Namam. Archana",
          "dates": "13th"
      },
      {
          "name": "Avahanti Homam",
          "dates": "23rd"
      }
      ],
      "june": [
        {
          "name": "Varshikotsavam",
          "dates": "3rd to 5th"
        },
        {
            "name": "Avahanti Homam",
            "dates": "3rd to 5th"
        },
        {
            "name": "Rama Namam. Archana",
            "dates": "9th"
        },
        {
            "name": "Avahanti Homam",
            "dates": "22nd"
        }
      ],
      "july": [
        {
          "name": "Sri Madhavendra Saraswathi Aradhana",
          "dates": "2nd"
        },
        {
            "name": "Radha Kalyanam",
            "dates": "2nd to 3rd"
        },
        {
            "name": "Rama Namam. Archana",
            "dates": "6th"
        },
        {
          "name": "Rama Namam. Archana",
          "dates": "15th"
      },
      {
          "name": "Avahanti Homam",
          "dates": "21st"
      }
      ],
      "august": [
        {
          "name": "Rama Namam. Archana",
          "dates": "3rd"
      },
      {
          "name": "Avahanti Homam",
          "dates": "19th"
      },
      {
          "name": "Vishnusahasranama parayana for Avani masam, Ashwini Nakshtram",
          "dates": "24th"
      },
      {
          "name": "Rama Namam. Archana",
          "dates": "30th"
      },
      ],
      "september": [
        {
          "name": "Rama Namam. Archana",
          "dates": "15th"
        },
        {
            "name": "Avahanti Homam",
            "dates": "18th"
        },
        {
            "name": "Sanyasta  malayam  - Sri Bodhehdra Aradhane",
            "dates": "28th to 29th"
        }
      ],
      "october": [
        {
          "name": "Avahanti Homam",
          "dates": "17th"
      },
      {
          "name": "Rama Namam. Archana",
          "dates": "23rd"
      }
      ],
      "november": [
        {
          "name": "Avahanti Homam",
          "dates": "15th"
        },
        {
            "name": "Rama Namam. Archana",
            "dates": "20th"
        },
      ],
      "december": [
        {
          "name": "Bhagavatha Saptaham",
          "dates": "12th to 21st"
        },
        {
            "name": "Avahanti Homam",
            "dates": "15th"
        },
        {
            "name": "Rama Namam. Archana",
            "dates": "17th"
        },
        {
          "name": "Guru Parikrama - Perambur Stop",
          "dates": "31st"
        }
      ],
      "january": [
        {
          "name": "Avahanti Homam",
          "dates": "13th"
        },
        {
            "name": "Rama Namam. Archana",
            "dates": "14th"
        }
      ],
      "february": [
        {
          "name": "Rama Namam. Archana",
          "dates": "10th"
        },
        {
            "name": "Avahanti Homam",
            "dates": "12th"
        }
      ],
      "march": [
        {
          "name": "Rama Namam. Archana",
          "dates": "9th"
      },
      {
          "name": "Avahanti Homam",
          "dates": "14th"
      }
      ]
    },
    {
      "location": "Bodhendra Ucchishta Bhumi",
      "city": "Perambur",
      "april": [
        {
          "name": "Bhumi Pooja",
          "dates": "26th"
      },
      ],
      "may": [],
      "june": [],
      "july": [
        {
          "name": "Construction Start",
          "dates": "12th"
      },
      ],
      "august": [],
      "september": [],
      "october": [],
      "november": [],
      "december": [],
      "january": [],
      "february": [],
      "march": [],
    },
    {
      "location": "Veda Patashala",
      "city": "Perambur",
      "april": [],
      "may": [],
      "june": [],
      "july": [
        {
          "name": "Teacher house construction start",
          "dates": "12th"
      },
      {
          "name": "Veda Patashala start with temporary location",
          "dates": "12th"
      },
      ],
      "august": [],
      "september": [],
      "october": [],
      "november": [],
      "december": [],
      "january": [],
      "february": [],
      "march": [],
    },
    {
      "location": "Goshala",
      "city": "Perambur",
      "april": [],
      "may": [],
      "june": [],
      "july": [],
      "august": [],
      "september": [],
      "october": [],
      "november": [],
      "december": [],
      "january": [
        {
          "name": "Maat Pongal/Kanumu",
          "dates": "16th"
      },
      ],
      "february": [],
      "march": [],
    },
    {
      "location": "Akhilandeshwari Sametha Jambukeshwara Swamy Temple",
      "city": "Perambur",
      "april": [],
      "may": [],
      "june": [],
      "july": [],
      "august": [],
      "september": [],
      "october": [],
      "november": [],
      "december": [],
      "january": [],
      "february": [],
      "march": [],
    },
    {
      "location": "Sridevi Bhudevi Sametha Varadarajaswamy Temple",
      "city": "Perambur",
      "april": [
        {
          "name": "Vasantotsavam",
          "dates": "26th to 27th"
      }
      ],
      "may": [],
      "june": [],
      "july": [],
      "august": [],
      "september": [],
      "october": [],
      "november": [],
      "december": [],
      "january": [],
      "february": [],
      "march": [],
    },
    {
      "location": "Poorna Pushkalamba Sametha Dharmashastha Temple",
      "city": "Perambur",
      "april": [],
      "may": [],
      "june": [],
      "july": [],
      "august": [],
      "september": [],
      "october": [],
      "november": [],
      "december": [],
      "january": [],
      "february": [],
      "march": [],
    },
    {
      "location": "Shivagami Sametha Prathapa Simheshwara Swamy Temple",
      "city": "Setur",
      "april": [],
      "may": [],
      "june": [],
      "july": [
        {
          "name": "Raja Gopuram - Bhoomi pooja & construction start",
          "dates": "12th"
      }
      ],
      "august": [],
      "september": [],
      "october": [],
      "november": [
        {
          "name": "Rajagopuram completion (Estimated)",
          "dates": "30th"
      }
      ],
      "december": [],
      "january": [],
      "february": [],
      "march": [],
    },
    {
      "location": "Lakshmi Narayana Swamy Temple",
      "city": "Setur",
      "april": [],
      "may": [],
      "june": [],
      "july": [],
      "august": [],
      "september": [],
      "october": [],
      "november": [],
      "december": [],
      "january": [],
      "february": [],
      "march": [],
    },
    {
      "location": "Guruji Patashala",
      "city": "Bengaluru",
      "april": [],
      "may": [],
      "june": [],
      "july": [],
      "august": [],
      "september": [],
      "october": [],
      "november": [],
      "december": [
        {
          "name": "Maha Periyava Aradhana",
          "dates": "27th"
      }
      ],
      "january": [],
      "february": [],
      "march": [],
    }
];
  return (
    <Box pt={2} pb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card
              sx={{
                padding: ".1rem 2rem",
                height: "100%",
              }}
            >
              <FlexBox
                height="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <H2>Krodhi Samvatsara Events</H2>
              </FlexBox>
            </Card>
          </Grid>

        </Grid>

        <Grid container spacing={3} pt={4} paddingX={4}>
        <CustomTable data={eventsList} columnShape={EventsColumnShape} />
        </Grid>
    </Box>
  );
};

export default Events;

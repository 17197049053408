import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {setAboutBhakta} from '../../actions'

const HTMLEditor = ({about, setAboutBhakta, language}) => {
  console.log("HTMLEditor initialization about:", about);
    const [value, setValue] = useState('');

    const setText = (content, delta, source, editor) => {
        setValue(content);
        setAboutBhakta(language,  content);
    };

    useEffect(() => {
      console.log("rendered html editor for lang:", language);
        if(about) {
            setText(about[language]);
        } else {
          setText('');
        }
    }, [language]);

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          [{ 'color': [] }, { 'background': [] }],
          ['link', 'image'],
          ['clean']
        ],
      };
    
      const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'color',
        'link', 'image'
      ];

    return (<ReactQuill theme="snow" value={value} onChange={setText} 
            toolbarOptions = {[['bold', 'italic', 'underline', 'strike'], ['link', 'image']]}
            modules={modules}
            formats={formats}/>)
};

/*class HTMLEditor extends Component {
    constructor(props) {
      super(props);
      this.state = {
        text: "",
      }
    }
  
    modules = {
      toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    };
  
    formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ];
  
    render() {
      return (
        <div className="text-editor">
          <ReactQuill theme="snow"
                      modules={this.modules}
                      formats={this.formats}>
          </ReactQuill>
        </div>
      );
    }
  }*/

const mapStateToProps = (state) => {
  console.log("about in HTMLEditor", state.bhakta);
  return {
    about: state.bhakta
  };
};

export default connect(mapStateToProps, {setAboutBhakta})(HTMLEditor);;
import { Box, Button, Card, Divider, Link } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
//import Link from '@mui/material/Link';
import AppAvatar from "./avatars/AppAvatar";
import FlexBetween from "./flexbox/FlexBetween";
import FlexBox from "./flexbox/FlexBox";
import { H5, H6, Tiny } from "./Typography";
import { lightTheme } from "./constants";
import Email from "../icons/Email";
import Call from "../icons/Call";
import Add from "../icons/Add";

const Contact = () => {
  return (
    <Card
      sx={{
        pb: 2,
        margin:'auto',
        width: '50%',
        marginTop:'30px',
        marginBottom:'200px'
      }}
    >
      <H5 padding={3} sx={{textAlign:'center'}}>We can be reached in the following ways</H5>
      <Divider />
      
      {accountList.map(item => (
        <FlexBetween
        sx={{
          borderBottom: 1,
          padding: "1rem 1.5rem",
          borderColor: (theme) =>
            lightTheme(theme) ? "grey.200" : "divider",
          "&:last-of-type": {
            borderBottom: 0,
          },
        }}
      >
        <FlexBox alignItems="center">
          <AppAvatar
            sx={{
              width: 30,
              height: 30,
              bgcolor: "pink[500]"
            }}
          >
            {item.image}
          </AppAvatar>

          <Box ml={1}>
            <H6>{item.title}</H6>
            <Tiny fontWeight={500} mt={0.3}>
              {item.body}
            </Tiny>
          </Box>
        </FlexBox>

      </FlexBetween>
      ))}
        
     
    </Card>
  );
};

const accountList = [
  {
    id: 1,
    title: "Address",
    body: "Registered Address: 220, Agraharam, Perambur, Needamanglam, TN-614404",
    image: <HomeIcon/>
  },
  {
    id: 2,
    title: "Email",
    body: <Link href="mailto:contact@ShriChandrashekharaBodhendraSaraswathiTrust.org">contact@ShriChandrashekharaBodhendraSaraswathiTrust.org</Link>,
    image: <Email/>
  },
  {
    id: 3,
    title: "Phone",
    body: "Periyava Computing, LLC",
    image: <Call/>
  }
];
export default Contact;

import React from 'react';
import { Box, Button, FormLabel, Grid, Radio, RadioGroup, FormControl } from "@mui/material";
import { Field, reduxForm } from 'redux-form';
import FlexBox from "../components/flexbox/FlexBox";
import AppTextField from "../components/input-fields/AppTextField";
import { useFormik } from "formik";
import * as Yup from "yup"; // component props interface

const AddLinkForm = (props) => {
  const { showAddLinkForm, setShowAddLinkForm, submitAddLinkForm } = props; // form field validation

  const [linkName, setLinkName] = React.useState('');
  const [linkURL, setLinkURL] = React.useState('');

  const validationSchema = Yup.object().shape({
    title: Yup.string().min(3, "Too Short").required("Title is Required!"),
    date: Yup.date().required("Date is Required!"),
    description: Yup.string()
      .min(10, "Too Short")
      .required("Description is Required!"),
  });
  const initialFormikValues = {
    title: "",
    date: "",
    description: "",
    statusColor: "#61A9FF",
    mentionClient: "",
  };
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialFormikValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      setShowAddLinkForm(false);
    },
  });
  const renderTextBox = ({ input, label, initialValues }) => (
    <div>
    
      <AppTextField
                      fullWidth
                      name={input.name}
                      label={label}
                      value={input.value}
                      onChange={input.onChange}
                      size="small"
                      variant="outlined"
                    />
    </div>
  )
  const handleAddLinkSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    console.log("Event receiveds");
    //submitAddLinkForm({"name":linkName, "url": linkURL});
    setLinkName('');
    setLinkURL('');
    submitAddLinkForm([linkName, linkURL]);
    //submitAddLinkForm(linkName);
  }
  return (
    <form id="addLinkForm" onSubmit={handleAddLinkSubmit}>
      <Box
        sx={{
          marginTop: 2,
          display: showAddLinkForm ? "auto" : "none",
        }}
      >
        
          <Grid>
            <FormControl fullWidth>
              {false && <Field name="name" component={renderTextBox} label="Name" value={linkName}/>}
              <AppTextField
                      fullWidth
                      name="name"
                      label="Name"
                      value={linkName}
                      onChange={(event) => setLinkName(event.target.value)}
                      size="small"
                      variant="outlined"
                    />
            </FormControl>
          </Grid>
          <Grid sx={{marginTop:1}}>
            <FormControl fullWidth>
              {false && <Field name="url" component={renderTextBox} label="URL" value={linkURL}/>}
              <AppTextField
                      fullWidth
                      name="url"
                      label="URL"
                      value={linkURL}
                      onChange={(event) => setLinkURL(event.target.value)}
                      size="small"
                      variant="outlined"
                    />
            </FormControl>
          </Grid>
        
        

        <FlexBox gap={2} sx={{marginTop:2}}>
          <Button variant="contained" fullWidth form="addLinkForm" onClick={(event) => handleAddLinkSubmit(event)}>
            Add Link
          </Button>

          <Button
            fullWidth
            variant="outlined"
            onClick={() => setShowAddLinkForm(false)}
          >
            Cancel
          </Button>
        </FlexBox>
      </Box>
    </form>
  );
};

export default AddLinkForm;

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green, orange } from '@mui/material/colors';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import MainImage from './components/MainImage';
import SelectionContainer from './components/SelectionContainer';
import About from './components/about';
import Footer from './components/Footer';
import Login from './components/session/login-v2';
import Trust from './components/Trust';
import Games from './components/Games';
import Events from './components/Events';
import UpcomingEvents from './components/UpcomingEvents';
import EventsTable from './components/wrappers/EventsTable';
import Contact from './components/Contact';
import BhaktaProfile from './components/BhaktaProfile';

function App() {

  const theme = createTheme({
    typography: {
      button: {
        textTransform: 'none',
        fontFamily:'Montserrat, sans-serif'
      },
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Sans Devanagari"'
      ].join(','),
    },
    palette: {
      primary: {
        //main: orange[500],
        main: '#006064',
        darker: '#053e85',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      }
    },
    components: {
      MuiButtonss: {
        styleOverrides: {
          outlined: {
            backgroundColor: '#64748B'
          },
          contained:{
            //backgroundColor: orange[500]
            backgroundColor: '#db622b'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          outlined: {
           //backgroundImage: 'linear-gradient(to top, #d299c2 0%, #fef9d7 100%)',
           //backgroundImage: orange[500]
           //color: 'green',
           //backgroundColor: orange[500],
           //color: orange[500],
           color: '#006064',
           //backgroundColor: '#202020',
          },
          containedPrimary: {
            //backgroundImage: 'linear-gradient(to top, #d299c2 0%, #fef9d7 100%)',
            //backgroundImage: orange[500]
            //color: 'green',
            //backgroundColor: orange[500],
            //backgroundColor: '#db622b',
            backgroundColor: '#006064'
           },
           contained:{
            //backgroundColor: orange[500]
            //backgroundColor: '#db622b'
            backgroundColor: '#006064'
          },
          text: {
           color: "red"
          }
        },
      }
    }
  });


  return (
    <ThemeProvider theme={theme}>
      <Router>
      <div>
        <Header></Header>
        <Routes>
        <Route path="/bhaktas/:name" element={<BhaktaProfile />} />
          <Route path='/bhaktas' element={<SelectionContainer/>} />
          <Route path='/trust' element={<Trust></Trust>} />
          <Route path='/events' element={<EventsTable></EventsTable>} />
          <Route path='/upcomingEvents' element={<UpcomingEvents></UpcomingEvents>} />
          <Route path='/games' element={<Games/>} />
          <Route path='/signIn' element={<Login/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/' element={<About/>} />
            </Routes>
        {false && <About></About>}
        {false && <SelectionContainer></SelectionContainer>}
        <Footer/>
          <div className="App">
            
          </div>
    </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;

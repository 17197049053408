import React, {Fragment, useRef, useState} from 'react';
import AppAvatar from "./avatars/AppAvatar";
import {
    Badge,
    Box,
    ButtonBase,
    Divider,
    styled,
    useMediaQuery,
  } from "@mui/material";
  import { H6, Small, Tiny } from "./Typography";
  import PopoverLayout from "./PopoverLayout"; // styled components
  import FlexBox from "./flexbox/FlexBox";




const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    padding: 5,
    marginLeft: 4,
    borderRadius: 30,
    border: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const StyledSmall = styled(Small)(({ theme }) => ({
    display: "block",
    cursor: "pointer",
    padding: "5px 1rem",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

function LoginBadge({userDetails}){
    const anchorRef = useRef(null);
    //const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const [open, setOpen] = useState(false);
    return (
        <Fragment>
            <StyledButtonBase
                disableRipple
                ref={anchorRef}
                onClick={() => setOpen(true)}
            >
                <Badge
                overlap="circular"
                variant="dot"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                sx={{
                    alignItems: "center",
                    "& .MuiBadge-badge": {
                    width: 11,
                    height: 11,
                    right: "4%",
                    borderRadius: "50%",
                    border: "2px solid #fff",
                    backgroundColor: "success.main",
                    },
                }}
                >
                
                    <Small mx={1} color="white">
                    Hi,{" "}
                    <Small fontWeight="600" display="inline">
                    {(userDetails && userDetails.firstName)? userDetails.firstName + " " + userDetails.lastName:"Aaron Cooper"}
                    </Small>
                    </Small>
                
                <AppAvatar
                    src={(userDetails && userDetails.profilePictureLink)? userDetails.profilePictureLink:"/static/avatar/001-man.svg"}
                    sx={{
                    width: 28,
                    height: 28,
                    }}
                />
                </Badge>
      </StyledButtonBase>
      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center" gap={1}>
            <AppAvatar
              src={userDetails?.profilePictureLink || "/static/avatar/001-man.svg"}
              sx={{
                width: 35,
                height: 35,
              }}
            />

            <Box>
              <H6>{(userDetails && userDetails.firstName)? userDetails.firstName + " " + userDetails.lastName:"Aaron Cooper"}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {userDetails?.email || "aaron@example.com"}
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>
          <a href="/logout">
            <StyledSmall>
                Sign Out
            </StyledSmall>
          </a>
        </Box>
      </PopoverLayout>
        </Fragment>
      );
}

export default LoginBadge;
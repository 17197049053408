import {reset, getFormValues} from 'redux-form';
import {
  ADD_TO_FETCHED_PLACES,
  FETCH_PLACES,
  UPDATE_PLACES,
  CREATE_PLACE_SUCCESS,
  CREATE_PLACE_ERROR,
  FETCH_PLACES_SUCCESS,
  FETCH_PLACES_ERROR,
  SHOW_PLACE,
  HIDE_PLACE,
  FETCH_CITIES,
  UPLOAD_FILE,
  API_ERROR,
  LOAD_STATUS,
  ADD_LINK,
  DELETE_LINK,
  SET_PROFILE,
  SET_LINKS,
  DELETE_LINKS,
  DELETE_FILES,
  SET_FILES,
  CLOSE_MODAL,
  OPEN_MODAL,
  SET_VIEW,
  RESET_ERROR,
  SET_ABOUT,
  FILTER_BHAKTAS,
  FILTER_BHAKTAS_BY_NAME,
  RESET_BHAKTAS,
  UPDATE_FILTER,
  UPDATE_GOD_FILTER,
  UPDATE_TYPE_FILTER,
  UPDATE_YUGA_FILTER,
  UPDATE_NAME_FILTER,
  UPDATE_CITY_FILTER,
  RESET_FILTER,
  SET_BHAKTA,
  FETCH_BHAKTA_ERROR,
  FETCH_BHAKTA_SUCCESS
} from './types';
import places from '../apis/places';
import bhaktas from '../assets/mocked-json/bhaktas';

export const updatePlaces = (formValues) => {
  return {
    type: UPDATE_PLACES,
    payload: formValues,
  };
};

export const fetchPlaces = () => async (dispatch, getState) => {
  console.log("process.env.NODE_ENV inside fetchPlaces:", process.env.NODE_ENV);
  dispatch({
    type: LOAD_STATUS,
    payload: true,
  });
  console.log("fetchPlace action fired:");
  try{
    let response;
    if (process && process.env && process.env.NODE_ENV === "development") {
      response = bhaktas;
    } else {
      response = await places.get('retrievePlaces');
    }
    console.log("response from fetchPlaces action:", response);
    dispatch({
      type: (response.data.err)? FETCH_PLACES_ERROR: FETCH_PLACES,
      payload: (response.data.err)?response.data.err:response.data.places,
    });
    if(!response.data.err){
      dispatch({type:FETCH_PLACES_SUCCESS, payload: response.data.places});
      dispatch({type: SET_PROFILE, payload:{allowEdit: response.data.allowEdit, userName: response.data.userName, userDetails: response.data.userDetails}});
    }
  }
  catch (err) {
    dispatch({
      type: FETCH_PLACES_ERROR,
      payload: "Server encountered an error while fetching places. Please try after a while. Complete Error:" + err,
    });
  }

  
}

export const fetchBhakta = (name) => async (dispatch, getState) => {
  console.log("process.env.NODE_ENV inside fetchPlaces:", process.env.NODE_ENV);
  dispatch({
    type: LOAD_STATUS,
    payload: true,
  });
  console.log("fetchBhakta action fired:");
  try{
    let response;
    if (process && process.env && process.env.NODE_ENV === "development") {
      response = {...bhaktas, data:{bhakta:bhaktas.data.places[bhaktas.data.places.length-1]}};
    } else {
      response = await places.get('findBhakta', {
        params: {
          name: name
        }
      });
    }
    console.log("response from fetchBhakta action:", response);
    dispatch({
      type: (response.data.err)? FETCH_PLACES_ERROR: SET_BHAKTA,
      payload: (response.data.err)?response.err:response.data.bhakta,
    });
    if(!response.data.err){
      dispatch({type:FETCH_BHAKTA_SUCCESS, payload: response.data.bhakta});
      //dispatch({type: SET_PROFILE, payload:{allowEdit: response.data.allowEdit, userName: response.data.userName, userDetails: response.data.userDetails}});
    }
  }
  catch (err) {
    dispatch({
      type: FETCH_PLACES_ERROR,
      payload: "Server encountered an error while fetching places. Please try after a while. Complete Error:" + err,
    });
  }

  
}

export const fetchCities = (formValues) => {
  return {
    type: FETCH_CITIES,
    payload: formValues,
  };
};

export const createPlace = (formValues, files, links) => async (dispatch, getState) => {
  dispatch({
    type: LOAD_STATUS,
    payload: true,
  });
  console.log("createPlace action fired:");
  console.log("links in actions:", links);
  formValues.about = getState().bhakta;
  const formData = new FormData();
  formData.append('formValues', JSON.stringify(formValues));
  formData.append('links', JSON.stringify(links));
  console.log("formData", formData);
  for(let file of files){
    formData.append(file.file.name, file.file);
  }
  try{
    const response = await places.post('createPlace', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }});
    dispatch({
      type: (response.err)? CREATE_PLACE_ERROR: CREATE_PLACE_SUCCESS,
      payload: (response.err)?response.err:response.result,
    });
    if(!response.err){
      dispatch(reset('createUserForm'))
      dispatch(fetchPlaces());
      dispatch(deleteLinks());
      dispatch(deleteFiles());
      dispatch({type: CLOSE_MODAL, payload:'CLOSE_MODAL'});
    }
  }
  catch (err) {
    dispatch({
      type: CREATE_PLACE_ERROR,
      payload: "Server encountered an error while creating the record. Please try after a while. Complete Error:" + err,
    });
  }

  
};

export const uploadFile = (file) => {
  return {
    type: UPLOAD_FILE,
    payload: file
  };
};

export const addLink = (link) => {
  return {
    type: ADD_LINK,
    payload: link
  };
};

export const deleteLink = (index) => {
  return {
    type: DELETE_LINK,
    payload: index
  };
};

export const setLinks = (links) => {
  return {
    type: SET_LINKS,
    payload: links
  }
};

export const deleteLinks = () => {
  return {
    type: DELETE_LINKS,
    payload: null
  }
};

export const deleteFiles = () => {
  return {
    type: DELETE_FILES,
    payload: null
  }
};

export const setFiles = (files) => {
  return {
    type: SET_FILES,
    payload: files
  }
};

export const openModal = (bhakta) => {
  return {
    type: OPEN_MODAL,
    payload: bhakta
  }
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
    payload: null
  }
};

export const updatePlace = (formValues) => async (dispatch, getState) => {
  dispatch({
    type: LOAD_STATUS,
    payload: true,
  });
  console.log("updatePlace action fired:");
  formValues.about = getState().bhakta;
  const formData = new FormData();
  formData.append('formValues', JSON.stringify(formValues));
  const linksObject = getState().links.map(link => {
    return {name: link[0], url:link[1]};
  })
  formData.append('links', JSON.stringify(linksObject));
  console.log("formData", formData);
  console.log("files", getState().files);
  for(let file of getState().files){
    file.file && formData.append(file.file.name, file.file);
  }
  try{
    const response = await places.post('updatePlace', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }});
    dispatch({
      type: (response.err)? CREATE_PLACE_ERROR: CREATE_PLACE_SUCCESS,
      payload: (response.err)?response.err:response.result,
    });
    if(!response.err){
      dispatch(reset('createUserForm'))
      dispatch(fetchPlaces());
      dispatch(deleteLinks());
      dispatch(deleteFiles());
      dispatch({type: CLOSE_MODAL, payload:'CLOSE_MODAL'});
    }
  }
  catch (err) {
    dispatch({
      type: CREATE_PLACE_ERROR,
      payload: "Server encountered an error while creating the record. Please try after a while. Complete Error:" + err,
    });
  }

  
};

export const setView = (viewType) => {
  return {
    type: SET_VIEW,
    payload: viewType
  }
};

export const resetError = () => {
  return {
    type: RESET_ERROR
  }
};

export const setAboutBhakta = (language, content) => {
    return {
      type: SET_ABOUT,
     payload: {language, content}
   }
  }

export const filterBhaktasByName = (name) => async (dispatch, getState) => {
  console.log("Filtering bhaktas by name:", name);
  console.log("filterForm:", getState().form.filterForm);
  console.log("createUserForm:", getState().form.createUserForm);
  console.log("filterForm values:", getFormValues('filterForm')(getState()));
  dispatch({
    type: FILTER_BHAKTAS_BY_NAME,
    payload: name,
  });
  /*return {
    type: FILTER_BHAKTAS_BY_NAME,
    payload: name,
  };*/
};

export const resetBhaktas = () => {
  return {
    type: RESET_BHAKTAS,
    payload: null,
  };
};

export const filterBhaktas = (filterAction, filterPayload) => async (dispatch, getState) => {
  const filterUpdateResult = await dispatch({
    type: filterAction,
    payload: filterPayload
  });
  const filterData = getState().filter;
  dispatch({
    type: FILTER_BHAKTAS,
    payload: filterData,
  });
}

export const updateFilter = (filterObj) => {
  console.log("updating filter in actions:", filterObj);
  return {
    type: UPDATE_FILTER,
    payload: filterObj
  }
}

export const updateGodFilter = (filterObj) => {
  console.log("updating god filter:", filterObj);
    return {
      type: UPDATE_GOD_FILTER,
      payload: filterObj   
  } 
}
export const updateTypeFilter = (filterObj) => {
  console.log("updating type filter:", filterObj);
  return {
    type: UPDATE_TYPE_FILTER,
    payload: filterObj   
} 
}
export const updateYugaFilter = (filterObj) => {
  return {
    type: UPDATE_YUGA_FILTER,
    payload: filterObj   
} 
}
export const updateNameFilter = (filterObj) => {
  return {
    type: UPDATE_NAME_FILTER,
    payload: filterObj   
} 
}
export const updateCityFilter = (filterObj) => {
  return {
    type: UPDATE_CITY_FILTER,
    payload: filterObj   
} 
}

export const resetFilter = () => async (dispatch, getState) => {
  const filterUpdateResult = await dispatch({
    type: RESET_FILTER,
    payload: null
  });
  const filterData = getState().filter;
  dispatch({
    type: FILTER_BHAKTAS,
    payload: filterData,
  });
}



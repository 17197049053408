import React, {useState} from 'react';
import {connect} from 'react-redux';
import { Box, Button, ButtonGroup, Grid, Stack } from "@mui/material";
import BharatMap from './BharatMap';
import TableView from './TableView';
import GridView from './GridView';
import FilterBar from './filters/FilterBar';
import SearchInput from './input-fields/SearchInput';
import SearchArea from './input-fields/search-area';
import {filterBhaktasByName, filterBhaktas, resetBhaktas} from '../actions';
import {UPDATE_NAME_FILTER} from '../actions/types';

const ViewBody = ({view, filterBhaktasByName, filterBhaktas, resetBhaktas, filterData}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    console.log("filterData:", filterData);
    //setSearchValue(value);
    //resetBhaktas();
    //filterBhaktasByName(value);
    filterBhaktas(UPDATE_NAME_FILTER, value);
  };
    return (
      <React.Fragment>
        <Grid>
          {false && <SearchInput
            disable_border
            placeholder="Search..."
            onChange={(e) => console.log(e.target.value)}
          />}
        </Grid>
        <Grid container sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Grid item xs={12} md={2}>
          <FilterBar/>
        </Grid>
        <Grid item xs={12} md={5}>
          {false && <SearchArea
            value={filterData.bhaktaName}
            onChange={handleSearch}
            //setValue={setSearchValue}
          />}
          <TableView/>
        </Grid>
        <Grid item xs={12} md={5}>
          <BharatMap/>
        </Grid>
        {false && <Grid item xs={12} md={8}>
              {view.viewType==="map" && <BharatMap/>}
              {view.viewType==="table" && <TableView/>}
              {view.viewType==="grid" && <GridView/>}
        </Grid>}
      </Grid>
      <Grid container sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Grid item xs={12} md={2}>
          <FilterBar/>
        </Grid>
        <Grid item xs={12}>
              {view.viewType==="map" && <BharatMap/>}
              {view.viewType==="table" && <TableView/>}
        </Grid>
      </Grid>
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log("view in ViewBody", state.view);
    return {
      view: state.view,
      //filterData: (state.form.filterForm)? state.form.filterForm.values: 'rANDOM',
      filterData:state.filter
    };
  };

export default connect(mapStateToProps, {filterBhaktasByName, filterBhaktas, resetBhaktas})(ViewBody);
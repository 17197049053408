import React from "react"; 
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import { Edit } from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  Stack,
  styled,
  Table,
  TableContainer,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import Divider from '@mui/material/Divider';
import AppAvatar from "./avatars/AppAvatar";
import { H5, Tiny } from "./Typography";
import { useState } from "react";
import TableHeader from "./TableHeader";
import BhaktaPopup from "./Popups/BhaktaPopup";
import { fetchBhakta, filterBhaktas, openModal, setFiles, setLinks, setAboutBhakta } from '../actions';
import BhaktaPreview from './BhaktaPreview'
import SearchArea from './input-fields/search-area';
import {UPDATE_NAME_FILTER} from '../actions/types';
import FlexBox from "./flexbox/FlexBox";
import FlexBetween from "./flexbox/FlexBetween";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableHeading = [
  {
    id: "name",
    label: "Name",
    alignRight: false,
  },
  {
    id: "type",
    label: "Type",
    alignRight: false,
  },
  {
    id: "location",
    label: "Location",
    alignRight: false,
  },
  {
    id: "yuga",
    label: "Yuga",
    alignRight: false,
  }
]; // list data

// styled components

const StyledTableCell = styled(TableCell)(() => ({
  paddingTop: 10,
  paddingBottom: 10,
}));

const TableView = ({places, openModal, setFiles, setLinks, fetchBhakta, filterBhaktas, filterData, setAboutBhakta}) => {
  //console.log("places in TableView:", places);
  const godImagesMap = {
    "Ganesha": {"cover":"/ganesha-cover.png", "logo":"/ganesha-logo.png"},
    "Shiva": {"cover":"/shiva-cover.png", "logo":"/shiva-logo.png"},
    "Vishnu": {"cover":"/vishnu-cover.png", "logo":"/vishnu-logo.png"},
    "Skanda": {"cover":"/skanda-cover.png", "logo":"/skanda-logo.png"},
    "Shakti": {"cover":"/shakti-cover.png", "logo":"/shakti-logo.png"},
  };
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = places.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = stableSort(
    places,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  //console.log("filteredUsers:", filteredUsers);
  const handleRowClick = (row) => {
    //setShowModal(true);
    //openModal(row);
    if (row.links) {
      const links = row.links.map(link => [link.name, link.url]);
      setLinks(links);
    }
    for (let prop in row.about) {
      //setAboutBhakta(prop, row.about[prop]);
    }
    setFiles(row.photos);
    //fetchBhakta(row.name);
  };
  const handleSearch = (value) => {
    console.log("filterData:", filterData);
    filterBhaktas(UPDATE_NAME_FILTER, value);
  };
  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      
        <SearchArea
              value={filterData.bhaktaName}
              onChange={handleSearch}
              placeholder="Bhakta Name..."
          />
      
        <TableContainer>
          <Table 
            sx={{
            [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
            }
          }}>
            <TableHeader
              order={order}
              orderBy={orderBy}
              heading={tableHeading}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              rowCount={places.length}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers.map((row, index) => {
                const { name, god, type, city, kalpa, manvantara, yuga } = row;
                const isItemSelected = selected.indexOf(name) !== -1;
                return (
                  <Link style={{ color: '#006064' }} to={ name}>
                    <React.Fragment>
                    <TableRow
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "transparent",
                          borderBottom: "none",
                          marginBottom:0
                        },
                      }}
                      onClick={() => handleRowClick(row)}
                      
                    >

                      <StyledTableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <AppAvatar alt={name} src={godImagesMap[god].logo} />
                          <Tiny fontWeight={600} color="text.primary">
                            {name}
                          </Tiny>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="left">{type}</StyledTableCell>
                      <StyledTableCell align="left">{city}</StyledTableCell>
                      <StyledTableCell align="left">{yuga}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan="4">
                        <BhaktaPreview bhakta={row}/>
                        <Divider/>
                      </TableCell>
                    </TableRow>
                    
                  </React.Fragment>
                  </Link>
                );
              })}
              
            </TableBody>
          </Table>
        </TableContainer>

      <TablePagination
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={places.length}
        rowsPerPageOptions={[5, 10, 25]}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(_) => handleChangeRowsPerPage}
      />
        <BhaktaPopup/>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    places: state.places.activePlaces,
    status: state.status,
    filterData:state.filter
  };
};

export default connect(mapStateToProps, {fetchBhakta, filterBhaktas, openModal, setFiles, setLinks, setAboutBhakta})(TableView);

import { connect } from 'react-redux';
import React, { Component } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Link} from "react-router-dom";
import '../App.css';
import AddUser from './wrappers/AddUser';
import LoginBadge from './LoginBadge';
import { Span } from './Typography';

class Header extends Component {
  constructor(props) {
    super(props);

    //this.state = { term: "" };
    this.state = {anchorElNav:null};
  }

  handleOpenNavMenu(event){
    console.log("Opening menu");
    console.log("state inside JS func:", this.state);
    this.setState({anchorElNav:event.currentTarget});
    console.log("state inside JS func after setState:", this.state);
  }

  handleCloseNavMenu(){
    this.setState({anchorElNav:null});
  }

  render() {

    let styles = {
      formStyle: {
        "flexDirection": "row"
      }
    };

    const handleOpenNavMenu = (event) => {
      console.log("Opening menu from JS function");
      console.log("state before:", this.state);
      this.setState({anchorElNav:event.currentTarget});
      console.log("state after setState:", this.state);
    };
  
    const handleCloseNavMenu = () => {
      this.state.anchorElNav = null;
    };

    const returnMUIAppBar = () => {
    const navItems = [{"name":'Home', "path":"/"}, {"name":'Bhaktas', "path": "/bhaktas"}, 
                    {"name":'Trust', "path":"/trust"}, {"name":'Events', "path":"/events"},
                    {"name":'Games', "path":"/games"}, {"name":'Contact', "path":"/contact"}];
      return (
        <Box className="saffron-background" sx={{ flexGrow: 1, backgroundColor:'#db622b' }}>
          <AppBar position="static" color="transparent">
            <Toolbar sx={{ backgroundColor:'#006064' }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              //onClick={handleOpenNavMenu}
              onClick={this.handleOpenNavMenu.bind(this)}
              color="default"
            >
              <MenuIcon style={{ color: '#fff' }}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(this.state.anchorElNav)}
              //onClose={handleCloseNavMenu}
              onClose={this.handleCloseNavMenu.bind(this)}
              sx={{
                display: { xs: 'block', md: 'none' },
                //backgroundColor:'#006064'
              }}
            >
              {navItems.map((page) => (
                <MenuItem key={page.name} onClick={this.handleCloseNavMenu.bind(this)}>
                  <Link style={{ color: '#006064' }} to={page.path}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
              {!this.props.profile.userName &&  
              <MenuItem key="Sign In" onClick={this.handleCloseNavMenu.bind(this)}>
                  <Link style={{ color: '#006064' }} to="/signIn">
                  <Typography textAlign="center">Sign In</Typography></Link>
              </MenuItem>}
            </Menu>
          </Box>
          
            <Typography variant="h5" component="div" sx={{ flexGrow: 2, color: 'white', fontWeight:'bold' }}>
                <a style={{color: 'white'}} href="/">Chaitanya Kuteera Prakashaha</a>
                
            </Typography>
          
          <React.Fragment>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {navItems.map((item) => (
                  <Button key={item.name} sx={{ color: '#fff' }}>
                    <Link style={{ color: '#fff' }} to={item.path}>{item.name}</Link>
                  </Button>
                ))} 
              {!this.props.profile.userName &&  
              <Button key="Sign In" sx={{ color: '#fff' }}>
                  <Link style={{ color: '#fff' }} to="/signIn">Sign In</Link>
              </Button>}
              </Box>
            
          </React.Fragment>
          
              {this.props.profile.allowEdit && <AddUser></AddUser>}
              {this.props.profile.userDetails && <LoginBadge userDetails={this.props.profile.userDetails}/>}
            </Toolbar>
          </AppBar>
        </Box>
      );
    };
    return returnMUIAppBar();
  }

}

const mapStateToProps = (state) => {
  console.log("profile:", state.profile);
  return {
    profile: state.profile
  };
};

export default connect(mapStateToProps, {})(Header);
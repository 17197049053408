import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { makeStyles } from "@mui/styles";
import { FaUserPlus } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import CreateUser from '../CreateUser';
import CreateUserForm from '../../forms/CreateUserForm';
import { fetchCities, uploadFile, createPlace, deleteLinks, resetError } from '../../actions';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    /*'& > *': {
      margin: theme.spacing(0.5),
    },*/
  },
}));

function AddUser({fetchCities, cities, createPlace, user, uploadFile, files, status, links, deleteLinks, resetError, formErrors}) {
    useEffect(() => {
        fetchCities();
    }, []);

    const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const handleClick = () => {
    setOpen(true);
    deleteLinks();
  };

  const handleClose = () => {
    setOpen(false);
    deleteLinks();
    resetError();
  };

  const handleSave = () => {
    const linksObject = links.map(link => {
      return {name: link[0], url:link[1]};
    })
    createPlace(user, files, linksObject);
    //setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Button variant="contained" onClick={handleClick}
      sx={{ color: '#006064', backgroundColor: 'white', borderColor: 'white' }}
        ><FaUserPlus/>  <span style={{marginLeft:5}}>Add Bhakta</span></Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
        <DialogTitle id="form-dialog-title">Add a new bhakta..</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter details of the bhakta and click Save.
          </DialogContentText>
            {status.result === "CREATE_PLACE_SUCCESS" && <Alert severity="success">Bhakta added successfully...</Alert>}
            {status.error && status.result === "CREATE_PLACE_ERROR" && <Alert severity="error">{status.error}</Alert>}
            <CreateUserForm cities={cities} handleFileupload={uploadFile}></CreateUserForm>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={status.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} disabled={formErrors}>
            Save Bhakta
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
    console.log("state in AddUser.js", state);
    return {
      cities: state.cities,
      user: (state.form.createUserForm)? state.form.createUserForm.values: null,
      formErrors: (state.form.createUserForm)? state.form.createUserForm.syncErrors: null,
      files: state.files,
      status: state.status,
      links: state.links
    };
  };

export default connect(mapStateToProps, {fetchCities, uploadFile, createPlace, deleteLinks, resetError})(AddUser);

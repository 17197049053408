import { Stack } from "@mui/material";
import { H5 } from "./Typography";
import IconWrapper from "./wrappers/IconWrapper";
import React from "react"; // ---------------------------------------------------

// ---------------------------------------------------
const Heading = ({ title, Icon }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <IconWrapper>
        <Icon
          sx={{
            color: "primary.main",
          }}
        />
      </IconWrapper>
      <H5 sx={{marginBottom: 3}}>{title}</H5>
    </Stack>
  );
};

export default Heading;

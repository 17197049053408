import { Button, ButtonGroup, Grid, Stack } from "@mui/material";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';
import { H2, H5 } from "./Typography";
import Footer from './Footer';
import SelectionForm from '../forms/selection';
import ViewHeader from './ViewHeader';
import ViewBody from './ViewBody';
import About from './about';
import { updatePlaces, fetchPlaces } from '../actions';
import '../App.css';


class SelectionContainer extends Component {
    constructor(props) {
      super(props);
  
      this.state = { currentView: "map"};
    }

    componentDidMount(){
      this.props.fetchPlaces();
    }

    validate = (formValues) => {
        this.props.updatePlaces(formValues);
      };
  
    render() {
      const allImages = ['main-image-0.png', 'main-image-1.png', 'main-image-2.png', 'main-image-3.png'];
      const images = ['main-image-0.png', 'main-image-3.png'];

      const returnGridBasedContainer = () => {
        return (
          <Grid className="selectionContainer" container spacing={0} sx={{marginTop: 0}}>
            {false && <ImageListItem sx={{backgroundSize:'cover'}}>
                        <img src='vande-guru-paramparam.png' alt='Guru Parampara'/>
            </ImageListItem>}
            {false && <ViewHeader/>}
            <ViewBody/>
            {false &&<Grid item xs={12} md={4}>
              <ImageList cols={1} rowHeight={350}>
                      {images.map((image) => (
                      <ImageListItem key={image}>
                        <img src={image} alt={image} />
                      </ImageListItem>
                      ))}
              </ImageList>
            </Grid>}
            <Grid item xs={12} md={8}>
            
                        {false && <About/>}
            </Grid>
            
            <Grid item xs={12} xl={12}>
                
            </Grid>
        </Grid>    
        );
      };
    
      return returnGridBasedContainer();
    }
  
  }
  
  export default connect(null, {updatePlaces, fetchPlaces})(SelectionContainer);
import React, {Component} from 'react';
import {connect} from 'react-redux';
import { fetchCities, createPlace } from '../actions';

import CreateUserForm from '../forms/CreateUserForm';

class CreateUser extends Component{

    componentDidMount() {
        this.props.fetchCities();
      }

      onSubmit = (formValues, files) => {
        this.props.createPlace(formValues, files);
      };

    render(){
        return (
            <CreateUserForm cities={this.props.cities} onSubmit={this.onSubmit}></CreateUserForm>
        );
    }
    
}

const mapStateToProps = (state) => {
    //console.log("cities in mapStateToProps", state);
    return {
      cities: state.cities
    };
  };

export default connect(mapStateToProps, {fetchCities, createPlace})(CreateUser);
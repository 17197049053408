import { Box, Card, Grid, IconButton, styled, Tab } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { makeStyles } from '@mui/styles';
import { useState } from "react";
import { connect } from 'react-redux';
import LightBox from "react-image-lightbox";
import { H6, Small, Tiny } from "./Typography";
import { uploadFile, deleteFiles, setFiles } from '../actions';
import FlexRowAlign from "./flexbox/FlexRowAlign";
import FlexBetween from "./flexbox/FlexBetween";

const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.action.disabledBackground,
}));
const UploadButton = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  border: "2px solid",
  alignItems: "center",
  justifyContent: "center",
  borderColor: theme.palette.background.paper,
  backgroundColor: theme.palette.action.disabledBackground,
}));

const Gallery = ({photos, editMode, uploadFile, deleteFiles, files}) => {

  const useStyles = makeStyles((theme) => ({
    imageList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    }
  }));

  const handleImageClick = (imgLink) => () => {
    window.open(imgLink, "_blank")
  };

  const classes = useStyles();

  const renderAddNewImage = () => {
    const onFileChange = event => {
      let image = {url: URL.createObjectURL(event.target.files[0]), fileName:"Image", file: event.target.files[0]};
      uploadFile(image); 
    }; 
    return (
      <Card
            sx={{
              padding: 3,
              minHeight: 200,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
        <ButtonWrapper>
              <UploadButton>
                <label htmlFor="upload-btn">
                  <input
                    accept="image/*"
                    id="upload-btn"
                    type="file"
                    style={{
                      display: "none",
                    }}
                    onChange={onFileChange} 
                  />
                  <IconButton component="span">
                    <PhotoCamera
                      sx={{
                        fontSize: 26,
                        color: "white",
                      }}
                    />
                  </IconButton>
                </label>
              </UploadButton>
            </ButtonWrapper>

            <Small
              marginTop={2}
              maxWidth={200}
              lineHeight={1.9}
              display="block"
              textAlign="center"
              color="text.secondary"
            >
              Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3 MB
            </Small>
      </Card>
    );
  };

  const renderImages = (photos) => {
    
    console.log("photos in Gallery:", photos);
    return (
      <div>
        <ImageList className={classes.imageList} cols={2.5}>
          
          {photos.map((photo) => (
          
          <ImageListItem key={photo.url}>
            <Grid item md={3} xs={4} key={photo.url}>
              <img
                width="100%"
                height="100%"
                src={photo.url}
                alt={photo.fileName}
                onClick={handleImageClick(photo.url)}
                style={{
                  cursor: "pointer",
                }}
              />
          </Grid>
        </ImageListItem>))}
          
        </ImageList>
      
      </div>
    );
  };

  const StyledFlexBetween = styled(FlexBetween)(() => ({
    margin: "auto",
    flexWrap: "wrap",
  }));

  return (
      <Grid container spacing={2} my={2} px={2}>
            <Grid item xs={12}>
              <StyledFlexBetween>
                {editMode && renderAddNewImage()}
              </StyledFlexBetween>
            </Grid>
            <Grid item xs={12}>
                {files && files.length > 0 && renderImages(files)}
            </Grid>
        </Grid>    
    
  );
};

const mapStateToProps = (state) => {
  return {
      files: state.files
    };
};

export default connect(mapStateToProps, {uploadFile, deleteFiles})(Gallery);

import React from 'react';
import { useState } from "react";
import { Box, Button, Card, Chip, Grid, Link, Stack, styled, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FlexBox from "./flexbox/FlexBox";
import FlexBetween from "./flexbox/FlexBetween";
import { H2, H5, H6, Paragraph, Small, Span } from "./Typography";
import DoneIcon from "../icons/DoneIcon";
import FolderSpecial from "../icons/FolderSpecial";
import Layers from "../icons/Layers";
import Premium from "../icons/Premium";

const StyledTabList = styled(TabList)(({ theme }) => ({
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 50,
    [theme.breakpoints.up("sm")]: {
      "& .MuiTabs-flexContainer": {
        justifyContent: "center",
      },
    },
  })); 

const Trust = () => {
    const [tabValue, setTabValue] = useState("1");
    const handleTabList = (_, value) => setTabValue(value);
    return (
        <Grid item xs={12} minHeight={500}>
          <Box pt={2} pb={4}>
            <TabContext value={tabValue}>
            <StyledTabList variant="scrollable" onChange={handleTabList}>
                <Tab disableRipple label="Shri Balamukunda Swamy Mattam Trust" value="1" />
                <Tab disableRipple label="Shri Chandrashekhara Bodhendra Saraswathi Trust" value="2" />
            </StyledTabList>
               
                <TabPanel value="1">
                    <BalamukundaSwamyMattam />
                </TabPanel>

                <TabPanel value="2">
                    <ShiChandraShekharaBodhendraSaraswathi />
                </TabPanel>
               
            </TabContext>
    </Box>
        </Grid>
    );
}

export default Trust;

const BalamukundaSwamyMattam = () => {
    return (
        <Card
        sx={{
            padding: 3,
        }}
        >
        <FlexBetween>
            <H5>Shri Bala Mukunda Swamy Mattam Trust</H5>
        </FlexBetween>

        <Paragraph mt={2} fontWeight={400}>
        Our Trust is deeply committed to the preservation and promotion of the rich heritage of Hindu Temples and Mutts. We own, maintain, administer, and, when needed, refurbish or reconstruct existing Hindu Temples and Mutts, ensuring their sanctity is upheld. Moreover, we extend our support to the refurbishment, reconstruction, maintenance, and administration of temples and mutts not owned by the Trust but considered essential for the continuity of these sacred places, safeguarding our religion and the community's welfare. 
        </Paragraph>
        <Paragraph mt={2} fontWeight={400}>
            We dedicate ourselves to the proper functioning, upkeep, and maintenance of religious practices associated with these institutions, including conducting ceremonies, festivals, and rituals while providing essential facilities and services to devotees in accordance with the Hindu Way of Life. Our Trust also takes pride in organising and managing events and celebrations, such as Kumbhaabhishekam, at the temples and mutts under our ownership or management. 
        </Paragraph>
        <Paragraph mt={2} fontWeight={400}>
            Additionally, we contribute, both in cash and kind, towards the successful conduct, maintenance, and support of events and celebrations at other temples, mutts, and trusts that share similar objectives. Join us in preserving and nurturing the spiritual sanctuaries that connect us to our profound cultural heritage.
        </Paragraph>
    </Card>
    );
}

const ShiChandraShekharaBodhendraSaraswathi = () => {
    return (
        <Card
        sx={{
            padding: 3,
        }}
        >
        <FlexBetween>
            <H5>Shri Chandra Shekhara Bodhendra Saraswathi Trust</H5>
        </FlexBetween>
        <Paragraph mt={2} fontWeight={400}>
            One of the primary objectives of this trust is to provide vedic education in traditional oral format through our veda patashala.
        </Paragraph>
        <Paragraph mt={2} fontWeight={400}>
        Veda Patashala is a sacred haven dedicated to teaching, promoting, and preserving the timeless wisdom of the Vedas, Agamas, and related scriptures.
        </Paragraph>
        <Paragraph mt={2} fontWeight={400}>
        Our trust, rooted in a deep reverence for these ancient texts, strives to nurture and support individuals committed to imparting this profound knowledge.
        </Paragraph>
        <Paragraph mt={2} fontWeight={400}>
        We have undertaken the construction, operation, and maintenance of centres, schools, and institutions focused on the study and dissemination of these scriptures. Our Veda Patashala is a sanctuary where the sacred vibrations of Vedic rituals and teachings resonate, fostering spiritual growth and understanding. We also extend a helping hand to financially disadvantaged students, offering scholarships that enable them to engage in the pursuit of Vedic knowledge.
        </Paragraph>
        <Paragraph mt={2} fontWeight={400}>
        Moreover, we actively publish and disseminate educational materials, spreading awareness through various mediums. At our Veda Patashala, we also honour and support scholars and individuals who have made significant contributions to Veda, Agama, and related disciplines, recognising their dedication and invaluable work in preserving this ancient wisdom. Join us on this transformative journey of learning, enlightenment, and spiritual evolution.
        </Paragraph>
        <Paragraph mt={2} fontWeight={400}>
            Learn more about our trust and its objectives by visiting <Link target="_blank" href="https://shrichandrashekharabodhendrasaraswathitrust.org/">shrichandrashekharabodhendrasaraswathitrust.org</Link>
        </Paragraph>
    </Card>
    );
}

function SingleCard({
    planName,
    price,
    type,
    description,
    features,
    iconColor,
    Icon,
  }) {
    return (
      <Card
        sx={{
          borderRadius: "40px",
          padding: 3,
          width: 290,
        }}
      >
        <Chip
          label={
            <Icon
              sx={{
                color: `${iconColor}.main`,
                display: "block",
              }}
            />
          }
          sx={{
            width: 35,
            height: 35,
            borderRadius: "4px",
            "& .MuiChip-label": {
              padding: 0,
            },
            backgroundColor: `${iconColor}.light`,
          }}
        />
  
        <H2 mt={2}>
          ${price}
          <Span fontSize={12} color="text.secondary">
            / {type}
          </Span>
        </H2>
  
        <H2 mt={2}>{planName}</H2>
        <H6 fontSize={12} color="text.secondary">
          {description}
        </H6>
        <Stack minHeight={200} mt={3} spacing={1.5}>
          {features.map((item, index) => (
            <FlexBox alignItems="end" gap={1} key={index}>
              <DoneIcon color="success" />
              <Small fontSize={13}>{item}</Small>
            </FlexBox>
          ))}
        </Stack>
  
        <Button
          variant="outlined"
          fullWidth
          sx={{
            borderColor: "primary.main",
            color: "primary.main",
            borderRadius: 25,
          }}
        >
          Get Started
        </Button>
      </Card>
    );
  }
import React from 'react';
import { Box, Button, Card, Chip, Grid, Stack, styled } from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import FlexBox from "./flexbox/FlexBox";
import { H2, H5, H6, Small, Span } from "./Typography";
import DoneIcon from "../icons/DoneIcon";
import FolderSpecial from "../icons/FolderSpecial";
import Layers from "../icons/Layers";
import Premium from "../icons/Premium";

const Games = () => {
    return (
        <Grid item xs={12} mt={5} sx={{minHeight: 500}}>
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={3}
            justifyContent="center"
          >
            <SingleCard
              price={49}
              type="Month"
              Icon={PeopleIcon}
              planName="Kahoot"
              iconColor="warning"
              description="Quiz on Bhaktas"
              features={[
                "Play with peers",
                "Fun leaderboards",
                "Sign In required",
              ]}
            />
            <SingleCard
              price={99}
              type="Month"
              planName="Puzzle"
              iconColor="primary"
              Icon={PersonIcon}
              description="Assemble puzzle pieces to form images"
              features={[
                "Images of Bhaktas, Saints and Gods",
                "Time based leaderboards",
                "Sign In required"
              ]}
            />
           
          </Stack>
        </Grid>
    );
}

export default Games;

function SingleCard({
    planName,
    price,
    type,
    description,
    features,
    iconColor,
    Icon,
  }) {
    return (
      <Card
        sx={{
          borderRadius: "40px",
          padding: 3,
          width: 290,
        }}
      >
        <Chip
          label={
            <Icon
              sx={{
                color: `${iconColor}.main`,
                display: "block",
              }}
            />
          }
          sx={{
            width: 35,
            height: 35,
            borderRadius: "4px",
            "& .MuiChip-label": {
              padding: 0,
            },
            backgroundColor: `${iconColor}.light`,
          }}
        />
  
        {false && <H2 mt={2}>
          ${price}
          <Span fontSize={12} color="text.secondary">
            / {type}
          </Span>
        </H2>}
  
        <H2 mt={2}>{planName}</H2>
        <H6 fontSize={12} color="text.secondary">
          {description}
        </H6>
        <Stack minHeight={200} mt={3} spacing={1.5}>
          {features.map((item, index) => (
            <FlexBox alignItems="end" gap={1} key={index}>
              <DoneIcon color="success" />
              <Small fontSize={13}>{item}</Small>
            </FlexBox>
          ))}
        </Stack>
  
        <Button
          variant="outlined"
          fullWidth
          sx={{
            borderColor: "primary.main",
            color: "primary.main",
            borderRadius: 25,
          }}
        >
          Coming Soon
        </Button>
      </Card>
    );
  }
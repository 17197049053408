import {
  Box,
  Button,
  Card,
  Divider,
  styled
} from "@mui/material";
import FlexBetween from "../flexbox/FlexBetween";
import FlexRowAlign from "../flexbox/FlexRowAlign";
import { H1, H3, Paragraph, Small } from "../Typography";
import FacebookIcon from "../../icons/FacebookIcon";
import GoogleIcon from "../../icons/GoogleIcon";

export const SocialIconButton = styled(Button)(({ theme }) => ({
  width: "48%",
  height: 48,
  fontSize: 13,
  borderRadius: "6px",
  border: "2px solid",
  borderColor: theme.palette.divider,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "0.5rem",
  },
}));

const Login = () => {
  return (
    <FlexRowAlign
      flexDirection="column"
      sx={{
        height: {
          sm: "100%",
        },
        minHeight: 500,
        marginTop:5
      }}
    >
      <Card
        sx={{
          padding: 4,
          maxWidth: 600,
          boxShadow: 1,
        }}
      >
        <FlexRowAlign flexDirection="column" mb={5}>
          <Box width={38} mb={1}>
            <img src="/dakshinamurthy.jpeg" width="100%" alt="Uko Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Sign In to Chaithanya Kuteera Prakashaha
          </H1>
        </FlexRowAlign>

        <FlexBetween flexWrap="wrap" my="1rem">
            <SocialIconButton // onClick={loginWithGoogle}
              startIcon={
                <GoogleIcon
                  sx={{
                    mr: 1,
                  }}
                />
              }
              href="/auth/google"
            >
              Sign in with Google
            </SocialIconButton>

          <Divider
            sx={{
              my: 3,
              width: "100%",
              alignItems: "center",
            }}
          >
            
          </Divider>


          <Paragraph
            marginLeft="auto"
            marginRight="auto"
            mt={2}
            color="text.disabled"
          >
            Sign In is required to participate in games - Kahoot and Puzzles.
          </Paragraph>
        </FlexBetween>
      </Card>
    </FlexRowAlign>
  );
};

export default Login;

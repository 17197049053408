import { Add } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import { Button, Card, Grid, Tab, useMediaQuery } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import { Box, styled } from "@mui/system";
import FlexBox from "./flexbox/FlexBox";
import { H2, H4, H5 } from "./Typography";
import ProjectCard from "./ProjectCard";
import { useState } from "react"; // styled component

const StyledTabList = styled(TabList)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
    "& .Mui-selected": {
      "& h2, & h5": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

const UpcomingEvents = () => {
  const [value, setValue] = useState("0");
  const [openModal, setOpenModal] = useState(false);
  const [projectMoreEl, setProjectMoreEl] = useState(null);
  const downSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleProjectMoreClose = () => setProjectMoreEl(null);

  const handleProjectMoreOpen = (event) => {
    setProjectMoreEl(event.currentTarget);
  };

  const projectList = [
    "in-progress",
    "upcoming",
    "blocked",
    "projects-total",
    "in-progress",
    "upcoming",
    "blocked",
    "projects-total",
    "upcoming",
  ];
  const filterList = projectList.filter(
    (item) =>
      value === "0" ||
      (item === "upcoming" && value === "1") ||
      (item === "blocked" && value === "2") ||
      (item === "projects-total" && value === "2")
  );
  const eventsList = [
    {
        "month": "April",
        "tamilMasam": "Panguni/Phalguna",
        "teluguMasam": "Chithirai/Chaithra",
        "events":[
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [15]
            },
            {
                "name": "Rama Navaham",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [17, 18, 19, 20, 21, 22, 23, 24, 25]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": "23rd"
            },
            {
                "name": "Bhumi Pooja - Ucchishta Bhumi",
                "location": "Bodhendra Ucchishta Bhumi",
                "city": "Perambur",
                "dates": [26]
            },
            {
                "name": "Vasantotsavam",
                "location": "Sridevi Bhudevi Sametha Varadarajaswamy Temple",
                "city": "Perambur",
                "dates": [26, 27]
            }
        ]
    },
    {
        "month": "May",
        "tamilMasam": "Chithirai/Chaithra",
        "teluguMasam": "Vaikaasi/Vaishaka",
        "events":[
            {
                "name": "Vishnu Sahasra Namam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [2]
            },
            {
                "name": "Bodhendra Ashtottaram",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [2]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [13]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [23]
            }
        ]
    },
    {
        "month": "June",
        "tamilMasam": "Vaikaasi/Vaishaka",
        "teluguMasam": "Aani/Jyeshta",
        "events":[
            {
                "name": "Varshikotsavam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "dates": [3, 4, 5]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "dates": [3, 4, 5]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [9]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [22]
            }
        ]
    },
    {
        "month": "July",
        "tamilMasam": "Aani/Jyeshta",
        "teluguMasam": "Aadi/Aashada",
        "events":[
            {
                "name": "Sri Madhevendra Saraswathi Aradhana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "dates": [2]
            },
            {
                "name": "Radha Kalyanam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "dates": [2, 3]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [6]
            },
            {
                "name": "Construction Start",
                "location": "Bodhendra Ucchishta Bhumi",
                "dates": [12]
            },
            {
                "name": "Teacher house construction start",
                "location": "Veda Parashala - Perambur",
                "dates": [12]
            },
            {
                "name": "Raja Gopuram - Bhoomi pooja & construction start",
                "location": "Shivagami Sametha Prathapa Simheshwara Swamy Temple",
                "dates": [12]
            },
            {
                "name": "Temp Veda Patashala start",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "dates": [12]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [15]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [21]
            }
        ]
    },
    {
        "month": "August",
        "tamilMasam": "Aadi/Aashada",
        "teluguMasam": "Aavani/Shravana",
        "events":[
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [3]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [15]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [19]
            },
            {
                "name": "Vishnusahasranama parayana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [24]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [30]
            },
        ]
    },
    {
        "month": "September",
        "tamilMasam": "Aavani/Shravana",
        "teluguMasam": "Purattaasi/Bhadrapada",
        "events":[
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [15]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [18]
            },
            {
                "name": "Sanyasta  malayam  - Sri Bodhehdra Aradhane",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [28, 29]
            }
        ]
    },
    {
        "month": "October",
        "tamilMasam": "Purattaasi/Bhadrapada",
        "teluguMasam": "Aippasi/Aaswiyuja",
        "events":[
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [17]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [23]
            }
        ]
    },
    {
        "month": "November",
        "tamilMasam": "Aippasi/Aaswiyuja",
        "teluguMasam": "Karthikai/Karthika",
        "events":[
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [15]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [20]
            },
            {
                "name": "Rajagopuram completion",
                "location": "Shivagami Sametha Prathapa Simheshwara Swamy Temple",
                "city": "Setur",
                "dates": [30]
            }
        ]
    },
    {
        "month": "December",
        "tamilMasam": "Karthikai/Karthika",
        "teluguMasam": "Maargazhi/Maargasheersha",
        "events":[
            {
                "name": "Bhagavatha Saptaham",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "dates": [12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [15]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [17]
            },
            {
                "name": "Maha Periyava Aradhana",
                "location": "Guruji Patashala - Bengaluru",
                "city": "Bengaluru",
                "dates": [27]
            },
            {
                "name": "Guru Parikrama - Perambur Stop",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "dates": [31]
            }
        ]
    },
    {
        "month": "January",
        "tamilMasam": "Maargazhi/Maargasheersha",
        "teluguMasam": "Thai/Pushya",
        "events":[
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [13]
            },
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [14]
            },
            {
                "name": "Maat Pongal/Kanumu",
                "location": "Goshala",
                "city": "Perambur",
                "dates": [16]
            },
        ]
    },
    {
        "month": "February",
        "tamilMasam": "Thai/Pushya",
        "teluguMasam": "Maasi/Maagha",
        "events":[
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [10]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [12]
            }
        ]
    },
    {
        "month": "March",
        "tamilMasam": "Maasi/Maagha",
        "teluguMasam": "Panguni/Phalguna",
        "events":[
            {
                "name": "Rama Namam. Archana",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [9]
            },
            {
                "name": "Avahanti Homam",
                "location": "Bodhendra Tapo Bhumi - Muttam",
                "city": "Perambur",
                "dates": [14]
            }
        ]
    }
];
const locations = [
    {"name": "Bodhendra Tapo Bhumi - Muttam", "color":"#8f0904"},
    {"name": "Bodhendra Ucchishta Bhumi", "color":"#fa5d02"},
    {"name": "Veda Patashala - Perambur", "color":"#bab104"},
    {"name": "Goshala", "color":"#92ab03"},
    {"name": "Akhilandeshwari Sametha Jambukeshwara Swamy Temple", "color":"#4eed05"},
    {"name": "Sridevi Bhudevi Sametha Varadarajaswamy Temple", "color":"#013d17"},
    {"name": "Poorna Pushkalamba Sametha Dharmashastha Temple", "color":"#0fa896"},
    {"name": "Shivagami Sametha Prathapa Simheshwara Swamy Temple", "color":"#036687"},
    {"name": "Lakshmi Narayana Swamy Temple", "color":"#0c025c"},
    {"name": "Guruji Patashala - Bengaluru", "color":"#5c0250"}
];
  return (
    <Box pt={2} pb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
           
              <FlexBox
                height="100%"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="column"
              >
                <H4>Krodhi Samvatsara Events</H4>
              </FlexBox>
            
          </Grid>

        </Grid>

         {true && <Grid container spacing={3} pt={1}>
          {eventsList.map((event, index) => (
            <Grid item xs={6} sm={6} lg={3} key={index}>
              <ProjectCard event={event} />
            </Grid>
          ))}
        </Grid>}

        {false && <Box sx={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
            {eventsList.map((event, index) => (
           <Grid item xs={6} sm={6} lg={3} key={index} sx={{alignSelf:"flex-start"}} pt={2}>
               <Box sx={{paddingX:1}}>
               <ProjectCard event={event} />
               </Box>    
           </Grid>
              
           
          ))}
        </Box>}
        <div style={{paddingLeft: 15}}>
                    {locations.map(loc => {
                        return (
                            <span style={{paddingRight:5}}>
                                <CircleIcon sx={{color:loc.color}} /> {loc.name}
                            </span>
                        );
                    })}
                </div>
    </Box>
  );
};

export default UpcomingEvents;

import React from 'react';
import { useState } from "react";
import {connect} from 'react-redux';
import { Alert, Backdrop, Button, CircularProgress, Stack } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FlexBox from '../flexbox/FlexBox';
import CreateUserForm from '../../forms/CreateUserForm';
import Bhakta from '../Bhakta';
import { closeModal, updatePlace, uploadFile, deleteLinks, deleteFiles } from '../../actions';


const BhaktaPopup = ({place, showModal, user, cities, status, profile, closeModal, updatePlace, uploadFile, deleteLinks, deleteFiles}) => {
    console.log("Bhaktapopup rendered");
    const [editMode, setEditMode] = useState(false);
    const handleClose = () => {
        console.log("closing modal");
        deleteFiles();
        deleteLinks();
        closeModal();
    };

      const handleUpdate = () => {
        updatePlace(user);      
      };
    
    return (
        status.showModal && <Dialog
        open={status.showModal}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        onClick={(e) => e.stopPropagation()}
        maxWidth="md"
      >
        <DialogContent dividers={true}>
          {!editMode && <div>
            <Bhakta place={status.bhakta} source="BhaktaPopup"/>
          </div>}
          {editMode && 
            <div>
              {status && status.result === "SUCCESS" && <Alert severity="success">Bhakta added successfully...</Alert>}
              {status && status.error && <Alert severity="error">{status.error}</Alert>}
              <CreateUserForm initialValues={{...status.bhakta, eon:status.bhakta.epoch, description:status.bhakta.info}} cities={cities} 
            handleFileupload={uploadFile}></CreateUserForm>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={status && status.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
            </div>}
          
        </DialogContent>
        <DialogActions>
        <Stack
            direction="row"
            alignItems="center"
            width="100%"
            height="100%"
            spacing={1.5}>
              <FlexBox width="100%" height="100%">
              <div>
            {profile.allowEdit && !editMode && <Button variant="outlined" onClick={()=> setEditMode(true)} color="primary">
              Edit
            </Button>}
            {profile.allowEdit && editMode && <Button variant="outlined" onClick={()=> setEditMode(false)} color="primary">
              Cancel - Edit
            </Button>}
            </div>
              </FlexBox>
              <div>
                <Stack direction="row">
                {editMode && <Button onClick={handleUpdate} color="primary"  variant="contained">Update</Button>}
                <Button sx={{marginLeft:1}} onClick={handleClose} color="primary"  variant="outlined">Close</Button>
                </Stack>
          </div>
            </Stack>
          
          
          
        </DialogActions>
      </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
      user: (state.form.createUserForm)? state.form.createUserForm.values: null,
      cities: state.cities,
      status: state.status,
      profile: state.profile
    };
  };

export default connect(mapStateToProps, { closeModal, updatePlace, uploadFile, deleteLinks, deleteFiles})(BhaktaPopup);
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {styled} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import AppTextField from "./input-fields/AppTextField";
import { addLink, deleteLink, setLinks } from '../actions';
import { H2, H5, H6 } from "./Typography";
import FlexBox from "./flexbox/FlexBox";
import FlexBetween from "./flexbox/FlexBetween";
import Add from "../icons/Add"
import AddLinkForm from "../forms/AddLinkForm";



const renderTextBox = ({ input, label }) => (
    <div>
        <AppTextField
                    fullWidth
                    name={input.name}
                    label={label}
                    value={input.value}
                    onChange={input.onChange}
                    id={input.name}
                    variant="outlined"
                    type={input.name}
                    size="small"
                  />
        
    </div>
)

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));




const Links = ({ addLink, deleteLink, setLinks, savedLinks, editMode, links }) => {
    /*useEffect(() => {
        setLinks(links);
    }, []);*/
    console.log("links on Link compnent render:", links);

    const [showAddLinkForm, setShowAddLinkForm] = useState(false);

    const submitAddLinkForm = (link) => {
        addLink(link);
        setShowAddLinkForm(false);
    };

    function AddLinkHeader({ title, subTitle, Icon, complete }) {
        const bgColor = "action.hover";
        return (
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            height="100%"
            spacing={1.5}
          >
            <FlexBox width="5%" height="100%"/>
            <FlexBox
              px={2}
              width="100%"
              height="100%"
              borderRadius="4px"
              alignItems="center"
              marginLeft="2"
              bgcolor={bgColor}
            >
              <H5 fontSize={18} color="text.primary" paddingLeft={0}>
                Links
              </H5>
            </FlexBox>
            {!showAddLinkForm && <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "24%",
              }}
              color="primary"
              variant="contained"
              onClick={() => setShowAddLinkForm(true)}
            >
                <Add
                  sx={{
                    color: "primary",
                    fontSize: 19,
                  }}
                />
            </IconButton>}
          </Stack>
        );
      }

    const renderAddLinkForm = () => {
        return (
            <div sx={{marginTop:2}}>
                <FlexBetween
              marginTop={6}
              flexWrap="wrap"
              justifyContent="space-between"
            >
                {false && <H5>Links</H5>}
                    {false && !showAddLinkForm && <Button sx={{alignSelf:'flex-end'}} color="primary" size="small" variant="contained" onClick={() => setShowAddLinkForm(true)}><AddIcon/></Button>}
            </FlexBetween>
            <Grid container spacing={4}>
                <Grid xs={12}>
                    <AddLinkHeader/>
                </Grid>
            </Grid>
               
                <AddLinkForm
                        showAddLinkForm={showAddLinkForm}
                        setShowAddLinkForm={setShowAddLinkForm}
                        submitAddLinkForm={submitAddLinkForm}
                        />
            </div>
        );
    };

    const HeadTableCell = styled(TableCell)(({ theme }) => ({
        fontWeight: 600,
        paddingBottom: 8,
        borderBottom: `1px solid ${theme.palette.divider}`,
        
      }));
      const BodyTableCell = styled(TableCell)(() => ({
        padding: "0.5rem 0",
        paddingLeft: "1rem",
        "&:first-of-type": {
          minWidth: 200,
        },
        "&:nth-of-type(2)": {
          minWidth: 150,
        },
        
      }));

      const deleteAddedLink = (index) => {
        console.log("deleting link at index:" + index);
        deleteLink(links[index]);
        console.log("DELETED LINK:", links[index]);
      }

    const renderViewOnlyTable = (links, savedLinks=false) => {
        console.log("links in renderViewOnlyTable:", links);
        if (savedLinks) {
            links = links.map(link => [link.name, link.url]);
        }
        return (
            <div>
                
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeadTableCell>Name</HeadTableCell>
                                <HeadTableCell>URL</HeadTableCell>
                                {editMode && <HeadTableCell></HeadTableCell>}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {links.map((link, index) => (
                            <TableRow key={index}>
                                <BodyTableCell>{link[0]}</BodyTableCell>
                                <BodyTableCell><a href={link[1]} target="blank">{link[1]}</a></BodyTableCell>
                                {editMode && <BodyTableCell><DeleteIcon onClick={() => deleteAddedLink(index)}/></BodyTableCell>}
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
            </TableContainer>
            </div>
            
        );
    };

    return (
        <Grid container spacing={2} sx={{marginTop:2}}>
            <Grid item xs={12}>
                {editMode && renderAddLinkForm()}
            </Grid>
            <Grid item xs={12}>
                {links && links.length > 0 && renderViewOnlyTable(links)}
            </Grid>
            <Grid item xs={12}>
                {false && savedLinks.length > 0 && renderViewOnlyTable(savedLinks, true)}
            </Grid>
        </Grid>
    );

};

const mapStateToProps = (state) => {
    return {
        links: state.links
      };
};


export default connect(mapStateToProps, { addLink, deleteLink, setLinks })(Links);;
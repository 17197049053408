import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Card, Grid, Tab, useMediaQuery } from "@mui/material";
import FlexBox from "../flexbox/FlexBox";
import FlexBetween from "../flexbox/FlexBetween";
import { H2, H4, H5,  Small, Span, Tiny} from "../Typography";


export default function EventsTable({}) {
  const events = [
    {
      "location": "Muttam",
      "apr-jun":[
        "9-19 Apr: Navaham",
        "3-4 Jun: Varshikotsavam",
        "Monthly Punarvasu"
      ],
      "jul-sep": [
        "2-3 Jul: Madhavendra Aradhana",
        "24 Aug: Vishnu Sahasra Namam",
        "Monthly Punarvasu"
      ],
      "oct-dec": [
        "2-3 Oct: Bodhendra Aradhane",
        "Monthly Punarvasu",
        "12-21 Dec: Bhagavatha Sapthaham",
        "27 Dec: Mahaperiyava Aradhana (pooja)",
        "30-31 Dec: Guru Parikrama"
      ],
      "jan-mar":[
        "Monthly Punarvasu",
        "Daily - Archana/Abhishekam/Bhajana"
      ],
      "apr":[
        "Monthly Punarvasu",
        "Daily - Archana/Abhishekam/Bhajana"
      ]
    },
    {
      "location": "Goshala",
      "apr-jun":[
        
      ],
      "jul-sep": [
        "Aug: Construction Start",
      ],
      "oct-dec": [
        "Plan for start in Thai masam/Ugadi"
      ],
      "jan-mar":[
        "Start Execution after Ugadi"
      ],
      "apr":[
        "Complete Construction & Start Maintenance"
      ]
    },
    {
      "location": "Veda Patashala",
      "apr-jun":[],
      "jul-sep": [
        "1 Jul: Vedapatashala Foundation Complete",
        "12 Jul: Vadhyar House Construction Start",
        "12 Jul: Patashala start @Muttam (Temp)",
        "12 Jul - 28 Aug: Avahanti Homam (48 days)",
        "30-31 Dec: Guru Parikrama"
      ],
      "oct-dec": [
        "Monthly Avahanti & Ganapathi Homam",
      ],
      "jan-mar":[
        "Jan - Patashala 80% Complete",
        "Monthly Avahanti & Ganapathi Homam",
      ],
      "apr":[
        "Apr: Patashala & Vadhyar house complete & Gruhapravesam",
        "Monthly Avahanti & Ganapathi Homam",
      ]
    },
    {
      "location": "Under Priviledged Support & Annadanam",
      "apr-jun":[],
      "jul-sep": [],
      "oct-dec": [],
      "jan-mar":[],
      "apr":[]
    },
    {
      "location": "Nandanavanam/Canal/River/Lake",
      "apr-jun":[],
      "jul-sep": [],
      "oct-dec": [],
      "jan-mar":[],
      "apr":[]
    },
    {
      "location": "Temple",
      "apr-jun":[],
      "jul-sep": [
        "12 Jul: Rajagopuram started",
      ],
      "oct-dec": [
        "Compound wall completion",
        "Start planning Kamakshi Amman & Adishankara"
      ],
      "jan-mar":[
        "Jan/Feb - Adishankaracharya and Kamakshi Amman Kumbhabhishekam",
        "Jan: Rajagopuram stone work completion",
      ],
      "apr":[
        "Rajagopuram: complete brick work (for July Kumbhabhishekam)",
      ]
    },
    {
      "location": "Ucchishta Bhumi",
      "apr-jun":[],
      "jul-sep": [
        "12 Jul: Construction started",
      ],
      "oct-dec": [],
      "jan-mar":[
        "60% Construction complete",
      ],
      "apr":[
        "Complete construction & Gruhapravesam",
      ]
    }
  ];
  return (
    <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
           
              <FlexBox
                height="100%"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="column"
              >
                <H4 sx={{paddingTop: 3}}>Krodhi Samvatsara Events</H4>
              </FlexBox>
            
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper} sx={{ paddingX:3, margin:0}}>
      <Table sx={{  minWidth: 0, border: 0 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell sx={{padding:"5px"}}>Initiative</TableCell>
            <TableCell sx={{padding:"5px"}}>Apr-Jun</TableCell>
            <TableCell sx={{padding:"5px"}}>Jul-Sep</TableCell>
            <TableCell sx={{padding:"5px"}}>Oct-Dec</TableCell>
            <TableCell sx={{padding:"5px"}}>Jan-Mar</TableCell>
            <TableCell sx={{padding:"5px"}}>Apr</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell scope="row" sx={{padding:"5px"}}>
                {event.location}
              </TableCell>
              <TableCell component="th" scope="row" sx={{padding:"5px"}}>
                {event["apr-jun"].map(subEvent => (<div>{subEvent}</div>))}
              </TableCell>
              <TableCell component="th" scope="row" sx={{padding:"5px"}}>
                {event["jul-sep"].map(subEvent => (<div>{subEvent}</div>))}
              </TableCell>
              <TableCell component="th" scope="row" sx={{padding:"5px"}}>
                {event["oct-dec"].map(subEvent => (<div>{subEvent}</div>))}
              </TableCell>
              <TableCell component="th" scope="row" sx={{padding:"5px"}}>
                {event["jan-mar"].map(subEvent => (<div>{subEvent}</div>))}
              </TableCell>
              <TableCell component="th" scope="row" sx={{padding:"5px"}}>
                {event["apr"].map(subEvent => (<div>{subEvent}</div>))}
              </TableCell>
            </TableRow>
          ))}
          <TableRow key="static1">
            <TableCell scope="row" sx={{padding:"5px"}}>
                Non-monetray Tasks
              </TableCell>
              <TableCell colSpan={5} component="th" scope="row" sx={{padding:"5px"}}>
                1. Daily Rama Japam (4 am to 5 am IST thru link). 2. Rama namam writing (any time) 3. Daily Vishnu Sahasra Namam Recitation (anytime) 
                4. Daily Mahaperiyava Ashtottaram Recitation (anytime) 5. Daily Bodhendra Ashtottaram Recitation (anytime) 6. Bhakta Story Writing (anytime)
              </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
          </Grid>

        </Grid>
    
  );
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled  } from "@mui/material";
import { H4, H5, Tiny } from "./Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import CreateUserForm from '../forms/CreateUserForm';
import Bhakta from './Bhakta';
import FlexBetween from "./flexbox/FlexBetween";
import { fetchCities, uploadFile, updatePlace, setLinks, deleteLinks, setFiles, deleteFiles, openModal, closeModal, setAboutBhakta } from '../actions';
import FlexBox from './flexbox/FlexBox';

class MapPositions extends Component{

    

    constructor(props) {
        super(props);
    
        this.state = { "open": false, "scroll": "paper", "editMode": false };
      }

    componentDidMount(){
      //this.props.closeModal();
      this.props.fetchCities();
    }

    render(){
        const handleClose = () => {
          console.log("closing modal");
          this.props.deleteFiles();
          this.props.deleteLinks();
            this.setState({"open":false});
            this.props.closeModal();
          };

        const handleUpdate = () => {
          this.props.updatePlace(this.props.user);      
        };

        const place = this.props.place;
        //console.log("place in mappositions:", place);
        return (
          <div>
              <Marker position={[place.coordinates.latitude, place.coordinates.longitude]} 
                eventHandlers={{
                  click: () => {
                    console.log("Opening modal for:", place.name);
                    this.setState({"open":true});
                    this.props.openModal(this.props.place);
                    if (this.props.place.links) {
                      console.log("links in map positions:", this.props.place.links);
                      const links = this.props.place.links.map(link => [link.name, link.url]);
                      this.props.setLinks(links);
                    }
                    this.props.setFiles(this.props.place.photos);
                    for (let prop in this.props.place.about) {
                      this.props.setAboutBhakta(prop, this.props.place.about[prop]);
                    }
                  },
              }}>
              </Marker>
            
            {false && <Dialog
        open={this.state.open && this.props.status.showModal}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        onClick={(e) => e.stopPropagation()}
        maxWidth="md"
      >
        <DialogContent dividers={this.state.scroll === 'paper'}>
          {!this.state.editMode && <div>
            <Bhakta place={place} source="MapPositions"/>
          </div>}
          {this.state.editMode && 
            <div>
              {this.props.status && this.props.status.result === "SUCCESS" && <Alert severity="success">Bhakta added successfully...</Alert>}
              {this.props.status && this.props.status.error && <Alert severity="error">{this.props.status.error}</Alert>}
              <CreateUserForm initialValues={{...place, eon:place.epoch, description:place.info}} cities={this.props.cities} 
            handleFileupload={this.props.uploadFile}></CreateUserForm>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.props.status && this.props.status.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
            </div>}
          
        </DialogContent>
        <DialogActions>
        <Stack
            direction="row"
            alignItems="center"
            width="100%"
            height="100%"
            spacing={1.5}>
              <FlexBox width="100%" height="100%">
              <div>
            {this.props.profile.allowEdit && !this.state.editMode && <Button variant="outlined" onClick={()=> this.setState({editMode:true})} color="primary">
              Edit
            </Button>}
            {this.props.profile.allowEdit && this.state.editMode && <Button variant="outlined" onClick={()=> this.setState({editMode:false})} color="primary">
              Cancel - Edit
            </Button>}
            </div>
              </FlexBox>
              <div>
                <Stack direction="row">
                {this.state.editMode && <Button onClick={handleUpdate} color="primary"  variant="contained">Update</Button>}
                <Button sx={{marginLeft:1}} onClick={handleClose} color="primary"  variant="outlined">Close</Button>
                </Stack>
          </div>
            </Stack>
          
          
          
        </DialogActions>
      </Dialog>}
          </div>
            
        )
    }
}

const mapStateToProps = (state) => {
  console.log("state in MapPositions.js", state);
  return {
    user: (state.form.createUserForm)? state.form.createUserForm.values: null,
    cities: state.cities,
    status: state.status,
    profile: state.profile
  };
};

export default connect(mapStateToProps, {updatePlace, fetchCities, uploadFile, setFiles, setLinks, deleteLinks, deleteFiles, openModal, closeModal, setAboutBhakta})(MapPositions);
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Row from 'react-bootstrap/Row';
import '../App.css';

const MainImage = () => {

  const images = ['main-image-0.png', 'main-image-1.png', 'main-image-2.png', 'main-image-3.png'];
    const useStyles = makeStyles((theme) => ({
      imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      },
      title: {
        color: theme.palette.primary.light,
      },
      titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      }
    }));
    const classes = useStyles();

  return (
      <Container className="mainImage">
        <Row>
          <Col>
            <ImageList className={classes.imageList} cols={4} rowHeight={350}>
              {images.map((image) => (
                <ImageListItem key={image}>
                  <img src={image} alt={image} />
                 
                </ImageListItem>
              ))}
            </ImageList>
          </Col>
        </Row>
      </Container>

    );

}

export default MainImage;